import {Dispatch} from "redux";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import axios from "axios";
import {config} from "../../../utils/config";
import {
    BindPaymentRequestActions
} from "../../../types/PaymentRequiest/BindPaymentRequest";

export const AuthActionCreator = {
    fetchOTPPassword: (phone: string) => async (dispatch: Dispatch<AuthActions>) => {
        try {
            dispatch({type: AuthActionTypes.FETCH_OTP})
            const response = await axios.get('auth/sendOneTimePassword', {
                baseURL: config.url.API_URL,
                params: {phone: phone}
            })
            if (response.data.status === 1) {
                dispatch({type: AuthActionTypes.FETCH_OTP_ERROR, payload: response.data.message})
            }
            if (response.data.status === 0) {
                dispatch({type: AuthActionTypes.FETCH_OTP_SUCCESS})
            }
        } catch (e: any) {
            dispatch({type: AuthActionTypes.FETCH_OTP_ERROR, payload: `ERROR OTP ${JSON.stringify(e.response)}`})
        }
    },
    fetchAuth: (phone: string, password: string, invoiceId?: string) => async (dispatch: Dispatch<AuthActions | BindPaymentRequestActions>) => {
        try {
            dispatch({type: AuthActionTypes.FETCH_AUTH})
            const response = await axios.post('auth', {phone: phone, password: password, invoiceId: invoiceId}, {
                baseURL: config.url.API_URL,
            })
            if (response.data.status === "UNAUTHORIZED") {
                dispatch({type: AuthActionTypes.FETCH_AUTH_ERROR, payload: response.data.message})
            } else {
                sessionStorage.setItem("token", response.data.token)
                localStorage.setItem("auth", "true")
                dispatch({type: AuthActionTypes.FETCH_AUTH_SUCCESS, payload: response.data.token})
            }
        } catch (e: any) {
            dispatch({type: AuthActionTypes.FETCH_AUTH_ERROR, payload: `ERROR AUTH ${JSON.stringify(e.response)}`})
        }
    },
    setPhone: (phone: string) => (dispatch: Dispatch<AuthActions>) => {
        dispatch({type: AuthActionTypes.SET_PHONE, payload: phone})
    },
    setPassword: (password: string) => (dispatch: Dispatch<AuthActions>) => {
        dispatch({type: AuthActionTypes.SET_PASSWORD, payload: password})
    },
    setIsAuth: (auth: boolean) => (dispatch: Dispatch<AuthActions>) => {
        if (!auth) {
            sessionStorage.removeItem("token");
            localStorage.removeItem("auth");
        }
        dispatch({type: AuthActionTypes.SET_AUTH, payload: auth})
    },
    setLoading: (loading: boolean) => (dispatch: Dispatch<AuthActions>) => {
        dispatch({type: AuthActionTypes.SET_LOADING, payload: loading})
    }
}