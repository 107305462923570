import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {ITransferData} from "../../../model/Transfer";
import {TransferActions, TransferActionTypes} from "../../../types/Crypto/Transfer";

export const ActionCreatorTransfer = {
    setTransferData: (data: ITransferData) => async (dispatch: Dispatch<TransferActions>) => {
        dispatch({type: TransferActionTypes.SET_DATA_TRANSFER, payload: data})
    },
    clearTransferData: () => async (dispatch: Dispatch<TransferActions>) => {
        dispatch({type: TransferActionTypes.CLEAR_STATE_TRANSFER})
    },
    fetchTransfer: (data: ITransferData) => async (dispatch: Dispatch<TransferActions | AuthActions>) => {
        try {
            dispatch({type: TransferActionTypes.FETCH_TRANSFER})
            const response = await axios.post('expi/transfer', data, {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            console.log(response)
            if(response.data.result === 0) {
                dispatch({type: TransferActionTypes.FETCH_TRANSFER_SUCCESS, payload: response.data})
            } else if(response.data.result > 0) {
                dispatch({type: TransferActionTypes.FETCH_TRANSFER_SUCCESS, payload: response.data})
            } else {
                dispatch({
                    type: TransferActionTypes.FETCH_TRANSFER_ERROR,
                    payload: response.data
                })
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: TransferActionTypes.FETCH_TRANSFER_ERROR,
                    payload: `Transfer ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR Transfer: ", e.response)
            }
        }
    },
}

