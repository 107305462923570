import React, {FC, useState} from 'react';
// import {useTypeSelector} from '../../hooks/useTypeSelector';
// import {useActions} from '../../hooks/useActions';
import {useHistory} from 'react-router-dom';
import {RouteNames} from '../../types/Route';
import {Form} from '../../components/UI/Form';
import {ButtonPrimary} from '../../components/UI/Buttons/ButtonPrimary';
import {Input} from '../../components/UI/Input';
import {Eye, EyeSlash} from '../../Icons';
import {Field} from '../../components/UI/Form/Field';
import {PrimaryLink} from '../../components/UI/Links';
import {FlexBox} from '../../components/UI/FlexBox';
import {H2} from '../../components/UI/Typography/H2';
import googlePlay from '../../assets/img/google-play.svg';
import appStore from '../../assets/img/app-store.svg';
import {PrimaryParagraph} from "../../components/UI/Typography/Paragraph/PimaryParagraph";

const Login: FC = () => {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    // const {errorAuth, OTPSent, phone, password} = useTypeSelector(state => state.auth);
    // const {invoiceId} = useTypeSelector(state => state.bindPaymentRequest);
    // const {fetchAuth} = useActions();

    const goToReg = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(RouteNames.SING_UP);
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const submit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('auth')
        // if (invoiceId) {
        //     fetchAuth(phone, password, invoiceId);
        // } else {
        //     fetchAuth(phone, password);
        // }
    };


    return (
        <React.Fragment>
                <H2
                    style={{marginBottom: "11px"}}
                >
                    Log in
                </H2>
                <PrimaryParagraph
                    style={{marginBottom: "40px"}}
                >
                    Don&rsquo;t have an&nbsp;account? <PrimaryLink
                    onClick={goToReg}
                >Sign&nbsp;up</PrimaryLink>
                </PrimaryParagraph>
                <Form
                    style={{marginBottom: "40px"}}
                    onSubmit={submit}
                >
                    <Field
                        title={"Email"}
                        id={"email"}
                    >
                        <Input
                            placeholder={"Email"}
                            id={"email"}
                            type={"email"}
                        />
                    </Field>
                    <Field
                        title={"Password"}
                        id={"password"}
                    >
                        <Input
                            id={"password"}
                            type={showPassword ? "test" : "password"}
                            placeholder={"8+ Characters, 1 Capital letter"}
                            adornment={showPassword ? <Eye/> : <EyeSlash/>}
                            onClick={handleShowPassword}
                        />
                        <PrimaryLink
                            style={{marginTop: '8px'}}
                            href={"#"}
                        >
                            Forgot your password?
                        </PrimaryLink>
                    </Field>
                    <ButtonPrimary
                        title={"Login"}
                        type={"submit"}
                    />
                </Form>
                <FlexBox>
                    <PrimaryLink
                        style={{marginRight: "16px"}}
                        href={"#"}
                        target={"_blank"}
                    >
                        <img src={googlePlay} alt="Google play"/>
                    </PrimaryLink>
                    <PrimaryLink
                        href={"#"}
                        target={"_blank"}
                    >
                        <img src={appStore} alt="App store"/>
                    </PrimaryLink>
                </FlexBox>
        </React.Fragment>
);
};

export default Login;