import {FiatDepositInfoActions, FiatDepositInfoActionTypes, FiatDepositInfoState} from "../../../../types/Fiat/Deposit/FiatDeposittInfo";

const initialState: FiatDepositInfoState = {
    responseFiatDepositInfo: {
        swift: {
            iban: "",
            name: "",
            address: "",
            bankSwift: "",
            bankAddress: "",
            details: ""
        },
        sepa: {
            iban: "",
            name: "",
            bankSwift: "",
            details: ""
        }
    },
    loading: false,
    error: null
}

export const fiatDepositInfoReducer = (state = initialState, action: FiatDepositInfoActions): FiatDepositInfoState => {
    switch (action.type) {
        case FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO:
            return {...state, loading: true, error: null};
        case FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_SUCCESS:
            return {...state, loading: false, responseFiatDepositInfo: action.payload};
        case FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}