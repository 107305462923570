import React from "react";

export enum RouteNames {
    LOGIN = "/login",
    SING_UP = "/sing_up",
    CONFIRM = "/confirm",
    BALANCE = "/balance",
    FUNDS = "/funds",
    TRANSFER = "/transfer",
    EXCHANGE = "/exchange",
    EXCHANGE_PARAMS = "/exchange/",
    HISTORY = "/history",
    PAYMENT_REQUEST = "/payment-request",
    SUPPORT = "/support:b24-okivzu.bitrix24.site/utm_frtn_support",
}

export interface IRoute {
    path: string;
    exact: boolean;
    component: React.ComponentType;
}