import {AuthActionCreator} from "./User/auth";
import {ActionCreatorUser} from "./User/user";
import {ActionCreatorAddress} from "./Crypto/address";
import {ActionCreatorWithdrawalCrypto} from "./Crypto/withdrawalCrypto";
import {ActionCreatorExchange} from "./Crypto/exchange";
import {ActionCreatorHistory} from "./User/history";
import {ActionCreatorTransfer} from "./Crypto/transfer";
import {ActionCreatorFiatDepositInfo} from "./Fiat/fiatDepositInfo";
import {ActionCreatorFiatWithdrawal} from "./Fiat/fiatWithdrawal";
import {ActionCreatorCardDeposit} from "./Fiat/cardDeposit";
import {ActionCreatorCurrencyList} from "./Dict/currencyList";
import {ActionCreatorAddWallet} from "./Crypto/addWallet";
import {ActionCreatorPaymentRequestList} from "./PaymentRequest/paymentRequestList";
import {ActionCreatorPayPaymentRequest} from "./PaymentRequest/payPaymentRequest";
import {ActionCreatorBindPaymentRequest} from "./PaymentRequest/bindPaymentRequest";

const exportedObject = {
    ...AuthActionCreator,
    ...ActionCreatorUser,
    ...ActionCreatorAddress,
    ...ActionCreatorWithdrawalCrypto,
    ...ActionCreatorTransfer,
    ...ActionCreatorExchange,
    ...ActionCreatorHistory,
    ...ActionCreatorFiatDepositInfo,
    ...ActionCreatorFiatWithdrawal,
    ...ActionCreatorCardDeposit,
    ...ActionCreatorCurrencyList,
    ...ActionCreatorAddWallet,
    ...ActionCreatorPaymentRequestList,
    ...ActionCreatorPayPaymentRequest,
    ...ActionCreatorBindPaymentRequest
};

export default exportedObject;
