const DEV = {
    url: {
        API_REG: 'https://reg-dev.bertes.eu/',
        API_URL: 'https://app-dev.evercash.eu/api/',
        API_ACQUIRING: 'https://api-dev.hybrid-acquiring.eu/api/',
        REDIRECT: 'https://dev.frtn.trade/balance'
    }
}

const STG = {
    url: {
        API_REG: 'https://reg-stg.bertes.eu/',
        API_URL: 'https://app-stg.evercash.eu/api/',
        API_ACQUIRING: 'https://api-stg.hybrid-acquiring.eu/api/',
        REDIRECT: 'https://stg.frtn.trade/balance'
    }
}

const PROD = {
    url: {
        API_REG: 'https://reg.bertes.eu/',
        API_URL: 'https://app.evercash.eu/api/',
        API_ACQUIRING: 'https://api.hybrid-acquiring.eu/api/',
        REDIRECT: 'https://pro.frtn.trade/balance'
    }
}
export const config = process.env.REACT_APP_STAGE === 'production' ? PROD : process.env.REACT_APP_STAGE === 'stg' ? STG : DEV;
