import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {ExchangeActions, ExchangeActionTypes} from "../../../types/Crypto/Exchange/Exchange";
import {IRateData} from "../../../model/IRate";
import {ExchangeRateActions, ExchangeRateActionTypes} from "../../../types/Crypto/Exchange/ExchangeRate";
import {IExchangeData} from "../../../model/IExchange";

export const ActionCreatorExchange = {
    setExchangeData: (data: IExchangeData) => async (dispatch: Dispatch<ExchangeActions>) => {
        dispatch({type: ExchangeActionTypes.SET_DATA_EXCHANGE, payload: data})
    },
    clearExchangeData: () => async (dispatch: Dispatch<ExchangeActions>) => {
        dispatch({type: ExchangeActionTypes.CLEAR_STATE_EXCHANGE})
    },
    fetchExchange: (data: IExchangeData) => async (dispatch: Dispatch<ExchangeActions | AuthActions>) => {
        try {
            dispatch({type: ExchangeActionTypes.FETCH_EXCHANGE})
            const response = await axios.post('expi/exchangeE', data, {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if(response.data.result === 1) {
                dispatch({
                    type: ExchangeActionTypes.FETCH_EXCHANGE_ERROR,
                    payload: response.data.desc
                })
            } else {
                dispatch({type: ExchangeActionTypes.FETCH_EXCHANGE_SUCCESS, payload: response.data})
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: ExchangeActionTypes.FETCH_EXCHANGE_ERROR,
                    payload: `Exchange ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR Exchange: ", e.response)
            }
        }
    },
    fetchExchangeRate: (data: IRateData) => async (dispatch: Dispatch<ExchangeRateActions | AuthActions>) => {
        try {
            dispatch({type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE})
            const response = await axios.post('expi/exchangeCreditRate', data,{
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if(response.data.status === 0) {
                dispatch({type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_SUCCESS, payload: response.data})
            } else {
                dispatch({type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_ERROR, payload:`ExchangeRate ERROR:  ${JSON.stringify(response.data)}`})
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_ERROR,
                    payload: `ExchangeRate ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR WithdrawalCrypto: ", e.response)
            }
        }
    },
    clearRate: () => async (dispatch: Dispatch<ExchangeRateActions | AuthActions>) => {
        dispatch({type: ExchangeRateActionTypes.CLEAR_RATE})
    },
}

