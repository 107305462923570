import {IFiatDepositInfo} from "../../../model/IFiatDepositInfo";


export interface FiatDepositInfoState {
    responseFiatDepositInfo: IFiatDepositInfo,
    error: null | string,
    loading: boolean
}

export enum FiatDepositInfoActionTypes {
    FETCH_FIAT_DEPOSIT_INFO = "FETCH_FIAT_DEPOSIT_INFO",
    FETCH_FIAT_DEPOSIT_INFO_SUCCESS = "FETCH_FIAT_DEPOSIT_INFO_SUCCESS",
    FETCH_FIAT_DEPOSIT_INFO_ERROR = "FETCH_FIAT_DEPOSIT_INFO_ERROR",
}

interface FetchFiatDepositInfoAction {
    type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO;
}

interface FetchFiatDepositInfoSuccessAction {
    type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_SUCCESS;
    payload: IFiatDepositInfo;
}

interface FetchFiatDepositInfoErrorAction {
    type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_ERROR;
    payload: string;
}

export type FiatDepositInfoActions =

     FetchFiatDepositInfoAction
    | FetchFiatDepositInfoSuccessAction
    | FetchFiatDepositInfoErrorAction