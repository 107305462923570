import {
    PaymentRequestListActions,
    PaymentRequestListActionTypes,
    PaymentRequestListState
} from "../../../types/PaymentRequiest/PaymentRequest";

const initialState: PaymentRequestListState = {
    responsePaymentRequestList: [
        {
            id: "",
            description: "",
            transactionId: "",
            amount: 0,
            currency: "",
            date: "",
            merchantName: "",
            status: {
                id: 0,
                name: "",
                description: "",
                final: false
            },
            redirectUrl: "",
            readyToPay: false,
            missingFunds: 0
        }
    ],
    loading: false,
    error: null
}

export const paymentRequestListReducer = (state = initialState, action: PaymentRequestListActions): PaymentRequestListState => {
    switch (action.type) {
        case PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST:
            return {...state, loading: true, error: null};
        case PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_SUCCESS:
            return {...state, loading: false, responsePaymentRequestList: action.payload};
        case PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}