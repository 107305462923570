import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {IFiatWithdrawalData} from "../../../model/IFiatWithdrawal";
import {FiatWithdrawalActions, FiatWithdrawalActionTypes} from "../../../types/Fiat/Withdrawal/FiatWithdarawal";
import {FiatWithdrawalFeeActions, FiatWithdrawalFeeActionTypes} from "../../../types/Fiat/Withdrawal/FiatWthdrawalFee";


export const ActionCreatorFiatWithdrawal = {
    setFiatWithdrawalData: (data: IFiatWithdrawalData) => async (dispatch: Dispatch<FiatWithdrawalActions>) => {
        dispatch({type: FiatWithdrawalActionTypes.SET_DATA_FIAT_WITHDRAWAL, payload: data})
    },
    clearFiatWithdrawalData: () => async (dispatch: Dispatch<FiatWithdrawalActions>) => {
        dispatch({type: FiatWithdrawalActionTypes.CLEAR_STATE_FIAT_WITHDRAWAL})
    },
    fetchFiatWithdrawal: (data: IFiatWithdrawalData) => async (dispatch: Dispatch<FiatWithdrawalActions | AuthActions>) => {
        try {
            dispatch({type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL})
            const response = await axios.post('expi/fiatWithdrawal', data, {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if (response.data.status === 0) {
                dispatch({
                    type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_ERROR, payload: response.data.desc})
                console.log(response.data.errors)
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_ERROR,
                    payload: `FiatWithdrawal ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR FiatWithdrawal: ", e.response)
            }
        }
    },
    fetchFiatWithdrawalFee: (amount: number | string) => async (dispatch: Dispatch<FiatWithdrawalFeeActions | AuthActions>) => {
        try {
            dispatch({type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE})
            const response = await axios.get('expi/fiatWithdrawalFeeCalc', {
                params: {amount: amount},
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            dispatch({type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_SUCCESS, payload: response.data})
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_ERROR,
                    payload: `FiatWithdrawalFee ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR WithdrawalCrypto: ", e.response)
            }
        }
    },
    clearFiatWithdrawalFee: () => async (dispatch: Dispatch<FiatWithdrawalFeeActions | AuthActions>) => {
        dispatch({type: FiatWithdrawalFeeActionTypes.CLEAR_FIAT_WITHDRAWAL_FEE})
    },
}

