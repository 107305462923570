import {IBindPaymentRequest} from "../../model/IPaymentRequest";

export interface BindPaymentRequestState {
    response: IBindPaymentRequest,
    invoiceId: string,
    error: null | string,
    loading: boolean
}

export enum BindPaymentRequestActionTypes {
    SET_PAYMENT_REQUEST_ID = "SET_PAYMENT_REQUEST_ID",
    CLEAR_ADD_PAYMENT_REQUEST = "CLEAR_ADD_PAYMENT_REQUEST",
    FETCH_ADD_PAYMENT_REQUEST = "FETCH_ADD_PAYMENT_REQUEST",
    FETCH_ADD_PAYMENT_REQUEST_SUCCESS = "FETCH_ADD_PAYMENT_REQUEST_SUCCESS",
    FETCH_ADD_PAYMENT_REQUEST_ERROR = "FETCH_ADD_PAYMENT_REQUEST_ERROR",
    FETCH_ADD_REG_PAYMENT_REQUEST = "FETCH_ADD_REG_PAYMENT_REQUEST",
    FETCH_ADD_REG_PAYMENT_REQUEST_SUCCESS = "FETCH_ADD_REG_PAYMENT_REQUEST_SUCCESS",
    FETCH_ADD_REG_PAYMENT_REQUEST_ERROR = "FETCH_ADD_  REG_PAYMENT_REQUEST_ERROR",
}

interface SetAddPaymentRequestAction {
    type: BindPaymentRequestActionTypes.SET_PAYMENT_REQUEST_ID;
    payload: string;
}

interface ClearAddPaymentRequestAction {
    type: BindPaymentRequestActionTypes.CLEAR_ADD_PAYMENT_REQUEST;
}

interface FetchAddPaymentRequestAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST;
}

interface FetchAddPaymentRequestSuccessAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_SUCCESS;
    payload: IBindPaymentRequest;
}

interface FetchAddPaymentRequestErrorAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_ERROR;
    payload: string;
}

interface FetchAddRegPaymentRequestAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST;
}

interface FetchAddRegPaymentRequestSuccessAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST_SUCCESS;
    payload: IBindPaymentRequest;
}

interface FetchAddRegPaymentRequestErrorAction {
    type: BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST_ERROR;
    payload: string;
}

export type BindPaymentRequestActions =
    SetAddPaymentRequestAction
    | ClearAddPaymentRequestAction
    | FetchAddPaymentRequestAction
    | FetchAddPaymentRequestSuccessAction
    | FetchAddPaymentRequestErrorAction
    | FetchAddRegPaymentRequestAction
    | FetchAddRegPaymentRequestSuccessAction
    | FetchAddRegPaymentRequestErrorAction