import {
    FiatWithdrawalFeeActions,
    FiatWithdrawalFeeActionTypes,
    FiatWithdrawalFeeState
} from "../../../../types/Fiat/Withdrawal/FiatWthdrawalFee";

const initialState: FiatWithdrawalFeeState = {
    responseFiatWithdrawalFee: 0,
    loading: false,
    error: null
}

export const fiatWithdrawalFeeReducer = (state = initialState, action: FiatWithdrawalFeeActions): FiatWithdrawalFeeState => {
    switch (action.type) {
        case FiatWithdrawalFeeActionTypes.CLEAR_FIAT_WITHDRAWAL_FEE:
            return {...state,
                responseFiatWithdrawalFee: 0,
                loading: false,
                error: null
            };
            case FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE:
            return {...state, loading: true, error: null};
        case FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_SUCCESS:
            return {...state, loading: false, responseFiatWithdrawalFee: action.payload};
        case FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}