import React from 'react';
import styles from './H2.module.css';

export function H2({children, ...props}:React.HTMLAttributes<HTMLParagraphElement>) {
    return (
        <h2
            className={styles.css}
            {...props}
        >
            {children}
        </h2>
    );
}
