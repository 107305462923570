import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {PayPaymentRequestActions, PayPaymentRequestActionTypes} from "../../../types/PaymentRequiest/PayPaymentRequest";


export const ActionCreatorPayPaymentRequest = {
    clearPayPaymentRequest: () => {
        return (dispatch: Dispatch<PayPaymentRequestActions>) => {
                dispatch({type: PayPaymentRequestActionTypes.CLEAR_PAY_PAYMENT_REQUEST})
        };
    },
    fetchPayPaymentRequest: (id: string) => {
        return async (dispatch: Dispatch<PayPaymentRequestActions>) => {
            try {
                dispatch({type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST})
                const response = await axios.get(`acquiring/payInvoice/${id}`, {
                    baseURL: config.url.API_URL,
                    headers: {
                        "Authorization": "Bearer " +sessionStorage.getItem("token")
                    }
                })
                dispatch({type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_SUCCESS, payload: response.data})
            } catch (e: any) {
                dispatch({
                    type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_ERROR,
                    payload: `PayPaymentRequest ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR PayPaymentRequest: ", e.response)
            }
        };
    },
}

