import React, {FC} from 'react';

import {store} from "./store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

import AppRouter from "./components/AppRouter/AppRouter";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

import "./App.css"

const App: FC = () => {
    const theme = createTheme({
        palette:{
            // primary: {
                // main: COLORS.textPrimary,
                // contrastText: COLORS.textSecondary,
            // },
        },
        typography: {
            fontSize: 17.5,
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },

    });
    // useEffect(()=>{
    //     (function(w,d,u){
    //         const s=d.createElement('script');
    //         s.async=true;
    //         s.src=u+'?'+(Date.now()/60000|0);
    //         const h=d.getElementsByTagName('script')[0];
    //         h.parentNode && h.parentNode.insertBefore(s,h);
    //     })(window,document,'https://bitrix.esterna-holding.com/upload/crm/site_button/loader_7_v4vuax.js');
    // },[])
    return (
        <Provider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AppRouter/>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default App;