import {
    BindPaymentRequestActions,
    BindPaymentRequestActionTypes,
    BindPaymentRequestState
} from "../../../types/PaymentRequiest/BindPaymentRequest";

const initialState: BindPaymentRequestState = {
    response: {
        status: 10,
        message: ""
    },
    invoiceId: "",
    loading: false,
    error: null
}

export const bindPaymentRequestReducer = (state = initialState, action: BindPaymentRequestActions): BindPaymentRequestState => {
    switch (action.type) {
        case BindPaymentRequestActionTypes.SET_PAYMENT_REQUEST_ID:
            return {...state, invoiceId: action.payload};
        case BindPaymentRequestActionTypes.CLEAR_ADD_PAYMENT_REQUEST:
            return {
                ...state,  response: {
                    status: 10,
                    message: ""
                },
                invoiceId: "",
                loading: false,
                error: null
            };
        case BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST:
            return {...state, loading: true, error: null};
        case BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_SUCCESS:
            return {...state, loading: false, response: action.payload};
        case BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_ERROR:
            return {...state, loading: false, error: action.payload};
        case BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST:
            return {...state, loading: true, error: null};
        case BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST_SUCCESS:
            return {...state, loading: false, response: action.payload};
        case BindPaymentRequestActionTypes.FETCH_ADD_REG_PAYMENT_REQUEST_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}