import {
    PayPaymentRequestActions,
    PayPaymentRequestActionTypes,
    PayPaymentRequestState
} from "../../../types/PaymentRequiest/PayPaymentRequest";


const initialState: PayPaymentRequestState = {
    responsePayPaymentRequest: false,
    loading: false,
    error: null
}

export const payPaymentRequestReducer = (state = initialState, action: PayPaymentRequestActions): PayPaymentRequestState => {
    switch (action.type) {
        case PayPaymentRequestActionTypes.CLEAR_PAY_PAYMENT_REQUEST:
            return {...state, responsePayPaymentRequest: false};
            case PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST:
            return {...state, loading: true, error: null};
        case PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_SUCCESS:
            return {...state, loading: false, responsePayPaymentRequest: action.payload};
        case PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}