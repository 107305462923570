import React, {useState} from 'react';
import {Box} from "@mui/material";
import {autoPlay} from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';

import logo from "../../../assets/img/logo.svg"
import imageSlider_1 from "../../../assets/img/login-slide-1.jpg";
import imageSlider_2 from "../../../assets/img/login-slide-2.jpg";
import imageSlider_3 from "../../../assets/img/login-slide-3.jpg";
import imageSlider_4 from "../../../assets/img/login-slide-4.jpg";
import imageSlider_5 from "../../../assets/img/login-slide-5.jpg";
import imageSlider_6 from "../../../assets/img/login-slide-6.jpg";
import imageSlider_7 from "../../../assets/img/login-slide-7.jpg";
import styles from "./authLayout.module.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export function PublicLayout({children}: React.PropsWithChildren<any>) {
    const [sliderIndex, setSliderIndex] = useState(0);

    const handleChangeSliderIndex = (index: any) => {
        setSliderIndex(index);
    };

    return (
        <main
            className={styles.main}
        >
            <div
                className={styles.wrapper}
            >
                <div
                    className={styles.formBox}
                >
                    <img
                        className={styles.logo}
                        src={logo}
                        alt="Logo"
                    />
                    {children}
                </div>
                <div
                    className={styles.sliderBox}
                >

                    <AutoPlaySwipeableViews
                        containerStyle={{
                            height: "100vh",
                            position: "relative"
                        }}
                        index={sliderIndex}
                        onChangeIndex={handleChangeSliderIndex}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_1}
                            alt={"imageSlider_1"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_2}
                            alt={"imageSlider_2"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_3}
                            alt={"imageSlider_3"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_4}
                            alt={"imageSlider_4"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_5}
                            alt={"imageSlider_5"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_6}
                            alt={"imageSlider_6"}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            src={imageSlider_7}
                            alt={"imageSlider_7"}
                        />
                    </AutoPlaySwipeableViews>
                </div>
            </div>
        </main>
    );
}