const IBAN = require('iban');

export function phoneValid(str: string) {
    const phoneMask = /^[0-9]*?$/
    if (phoneMask.test(str)) {
        return str;
    }
}

export function positiveNumberValid(str: string) {
    return Number(str) >= 0
}

export function swiftValid(str: string) {
    const swiftMask = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
    return swiftMask.test(str)
}

export function ibanValid(str: string) {
    return IBAN.isValid(str);
}


export function toFixed(x: any) {
    let char = ""

    if(x < 0){
        char = "-"
    }

    if (Math.abs(x) < 1.0) {
        let e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10,e-1);
            x = x.toString().split(".")[1]
            x = char + "0." + (new Array(e)).join('0') + x;
        }
    } else {
        let e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10,e);
            x += (new Array(e+1)).join('0');
        }
    }
    return x;
}