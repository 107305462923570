/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {Colors} from "../../../utils/constants";
import Logo from "../../../image/logo/logo_header.svg";
import miniLogo from "../../../image/loader/loader.png";
import {Avatar, CircularProgress, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import useWidthScreen from "../../../hooks/useWIdthScreen";

import Balance from '@mui/icons-material/AccountBalanceWalletOutlined';
import Funds from '@mui/icons-material/CreditCardOutlined';
import Transfer from '@mui/icons-material/SendOutlined';
import Exchange from '@mui/icons-material/CurrencyExchangeOutlined';
import History from '@mui/icons-material/RestoreOutlined';
// import Support from '@mui/icons-material/SupportAgentOutlined';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';
import {RouteNames} from "../../../types/Route";

import "../../../App.css"
import EuPi from "../../../image/currency/eupi_symbol.svg";
import EVER from "../../../image/currency/ever_symbol.svg";
import BTC from "../../../image/currency/bitcoin_symbol.svg";
import ETH from "../../../image/currency/eth_symbol.svg";
import {useActions} from "../../../hooks/useActions";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {Logout} from "@mui/icons-material";
import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";

const MenuList = [
    {to: RouteNames.BALANCE, name: 'Crypto wallets', icon: <Balance/>},
    {to: RouteNames.FUNDS, name: 'Top up/withdrawal EUR/EUPI', icon: <Funds/>},
    {to: RouteNames.TRANSFER, name: 'Internal transfer', icon: <Transfer/>},
    {to: RouteNames.EXCHANGE, name: 'Exchange', icon: <Exchange/>},
    {to: RouteNames.PAYMENT_REQUEST, name: 'Payment Request', icon: <AddShoppingCartOutlinedIcon/>},
    {to: RouteNames.HISTORY, name: 'History', icon: <History/>},
    // {to: RouteNames.SUPPORT, name: 'Support', icon: <Support/>},
]

const drawerWidth = 335;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Layout({children}: React.PropsWithChildren<any>) {
    const {fetchBalance, setIsAuth} = useActions();
    const {loading, error, user} = useTypeSelector(state => state.user)
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorBalance, setAnchorBalance] = React.useState<null | HTMLElement>(null);
    const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(null);
    const openProfile = Boolean(anchorProfile);
    const openBalance = Boolean(anchorBalance);
    const widthWindow = useWidthScreen();

    useEffect(() => {
        fetchBalance();
    }, [location])

    const handleClickBalance = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorBalance(event.currentTarget);
    };

    const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorProfile(event.currentTarget);
    };

    const handleCloseBalance = () => {
        setAnchorBalance(null);
    };
    const handleCloseProfile = () => {
        setAnchorProfile(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    if (error) {
        return <Typography>{error}</Typography>
    }
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    bgcolor: "#ffffff",

                }}
            >
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                color: Colors.secondary,
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => history.push(RouteNames.BALANCE)}
                            edge="start"
                            sx={{
                                color: "#ffffff",
                                marginRight: '36px',
                            }}
                        >
                            <img
                                style={{...(widthWindow < 650 ? {width: "46px", height: "46px"} : {width: "46px", height: "46px"})}}
                                src={widthWindow < 875 ? miniLogo : Logo}
                                alt="Fortune logo"
                            />
                        </IconButton>
                    </Box>
                    <Box
                        display="flex"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            // height={46}
                        >

                            <List

                                component="div"
                                aria-label="Device settings"
                                sx={{color: Colors.textPrimary, padding: "0"}}
                            >
                                <ListItem
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    aria-controls="lock-menu"
                                    aria-label="when device is locked"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickBalance}
                                >
                                    {widthWindow < 500 ?
                                        <Typography>
                                            {loading ? <CircularProgress size={15}
                                                                         sx={{color: Colors.primary}}/> : "€ " + user.totalBalance}
                                        </Typography>
                                        :
                                        <Typography>
                                            My balance: {loading ?
                                            <CircularProgress size={15}
                                                              sx={{color: Colors.primary}}/> : "€ " + user.totalBalance}
                                        </Typography>
                                    }
                                    <IconButton
                                    >
                                        <ArrowDropDownOutlined/>
                                    </IconButton>
                                </ListItem>
                            </List>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorBalance}
                                open={openBalance}
                                onClose={handleCloseBalance}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {user.balanceList.map((balance, index) => (
                                    <MenuItem
                                        key={index}
                                    >
                                        <ListItemText>
                                            {`${balance.name}: ${balance.balance} `}
                                            <img style={{height: 13}}
                                                 src={
                                                     balance.currency === "EPI"
                                                         ? EuPi
                                                         : balance.currency === "BTC"
                                                             ? BTC
                                                             : balance.currency === "ETH"
                                                                 ? ETH
                                                                 : balance.currency === "EVER" ? EVER
                                                                     : ""
                                                 }
                                                 alt={balance.name}/>
                                        </ListItemText>

                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <Tooltip title="Account settings">
                                <IconButton onClick={handleClickProfile} size="small" sx={{ml: 2}}>
                                    <Avatar sx={widthWindow < 500 ? {width: 36, height: 36} : {
                                        width: 46,
                                        height: 46
                                    }}>M</Avatar>
                                </IconButton>

                            </Tooltip>
                            <Menu
                                anchorEl={anchorProfile}
                                open={openProfile}
                                onClose={handleCloseProfile}
                                onClick={handleCloseProfile}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                <MenuItem>
                                    Hi {user.firstName} {user.lastName}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => setIsAuth(false)}
                                >
                                    <ListItemIcon>
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {MenuList.map((link) => (
                        <Link
                            key={link.to}
                            style={{
                                textDecoration: "none",
                                color: Colors.secondary,
                            }}
                            to={link.to}
                        >
                            <ListItem
                                sx={{
                                    ...(location.pathname === link.to && {
                                        color: Colors.primary,
                                        backgroundColor: Colors.bgColorPaper,
                                        borderRight: `2px solid ${Colors.primary}`
                                    })
                                }}
                                button
                            >
                                <ListItemIcon
                                    sx={{minWidth: 47, ...(location.pathname.includes(link.to) && {color: Colors.primary})}}
                                >
                                    {link.icon}
                                </ListItemIcon>
                                <ListItemText primary={link.name}/>
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Drawer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    width: "100%"
                }}
            >
                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <DrawerHeader/>
                    {loading
                        ? <Loader/>
                        : children
                    }
                </Box>
                {!loading && <Footer/>}
            </Box>
        </Box>
    );
}
