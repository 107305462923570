import {combineReducers} from "redux";
import {authReducer} from "./User/authReducer";
import {userReducer} from "./User/userReducer";
import {addressReducer} from "./Crypto/addressReducer";
import {withdrawalCryptoReducer} from "./Crypto/Withdrawal/withdrawalCryptoReducer";
import {exchangeReducer} from "./Crypto/Exchange/exchangeReducer";
import {exchangeRateReducer} from "./Crypto/Exchange/exchangeRateReducer";
import {historyReducer} from "./User/historyReducer";
import {transferReducer} from "./Crypto/Transfer/transferReducer";
import {fiatDepositInfoReducer} from "./Fiat/Deposit/fiatDepositInfoReducer";
import {fiatWithdrawalFeeReducer} from "./Fiat/Withdrawal/fiatWithdrawalFeeReducer";
import {fiatWithdrawalReducer} from "./Fiat/Withdrawal/fiatWithdrawalReducer";
import {cardDepositReducer} from "./Fiat/Deposit/cardDepositReducer";
import {currencyListReducer} from "./Dict/currencyListReducer";
import {addWalletReducer} from "./Crypto/addWalletReducer";
import {paymentRequestListReducer} from "./PaymentReuqest/paymentReuqestListReducer";
import {payPaymentRequestReducer} from "./PaymentReuqest/payPaymentReuqestReducer";
import {bindPaymentRequestReducer} from "./PaymentReuqest/bindPaymentReuqestReducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    addressWallet: addressReducer,
    withdrawalCrypto: withdrawalCryptoReducer,
    transfer: transferReducer,
    exchange: exchangeReducer,
    exchangeRate: exchangeRateReducer,
    history: historyReducer,
    fiatDepositInfo: fiatDepositInfoReducer,
    fiatWithdrawalFee: fiatWithdrawalFeeReducer,
    fiatWithdrawal: fiatWithdrawalReducer,
    cardDeposit: cardDepositReducer,
    currencyList: currencyListReducer,
    addWallet:  addWalletReducer,
    paymentRequestList: paymentRequestListReducer,
    payPaymentRequest: payPaymentRequestReducer,
    bindPaymentRequest: bindPaymentRequestReducer
})

export type RootState = ReturnType<typeof rootReducer>