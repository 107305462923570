import {IHistory} from "../../model/IHistory";


export interface HistoryState {
    responseHistory: IHistory[],
    error: null | string,
    loading: boolean
}

export enum HistoryActionTypes {
    FETCH_HISTORY = "FETCH_HISTORY",
    FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS",
    FETCH_HISTORY_ERROR = "FETCH_HISTORY_ERROR",
}

interface FetchHistoryAction {
    type: HistoryActionTypes.FETCH_HISTORY;
}

interface FetchHistorySuccessAction {
    type: HistoryActionTypes.FETCH_HISTORY_SUCCESS;
    payload: IHistory[];
}

interface FetchHistoryErrorAction {
    type: HistoryActionTypes.FETCH_HISTORY_ERROR;
    payload: string;
}


export type HistoryActions =
    FetchHistoryAction
    | FetchHistorySuccessAction
    | FetchHistoryErrorAction
