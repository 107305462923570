import {IWithdrawalCryptoResponse} from "../../model/IWithdrawalCryptoResponse";
import {IWithdrawalCryptoData} from "../../model/IWithdrawalCryptoData";

export interface WithdrawalCryptoState {
    order: IWithdrawalCryptoResponse;
    data: IWithdrawalCryptoData
    fee: number,
    error: null | string,
    loading: boolean,
}

export enum WithdrawalCryptoActionTypes {
    SET_DATA_WITHDRAWAL_CRYPTO = "SET_DATA_WITHDRAWAL_CRYPTO",
    CLEAR_STATE_WITHDRAWAL_CRYPTO = "CLEAR_STATE_WITHDRAWAL_CRYPTO",
    FETCH_WITHDRAWAL_CRYPTO = "FETCH_WITHDRAWAL_CRYPTO",
    FETCH_WITHDRAWAL_CRYPTO_SUCCESS = "FETCH_WITHDRAWAL_CRYPTO_SUCCESS",
    FETCH_WITHDRAWAL_CRYPTO_ERROR = "FETCH_WITHDRAWAL_CRYPTO_ERROR",
    CLEAR_WITHDRAWAL_CRYPTO_FEE = "CLEAR_WITHDRAWAL_CRYPTO_FEE",
    FETCH_WITHDRAWAL_CRYPTO_FEE = "FETCH_WITHDRAWAL_CRYPTO_FEE",
    FETCH_WITHDRAWAL_CRYPTO_FEE_SUCCESS = "FETCH_WITHDRAWAL_CRYPTO_FEE_SUCCESS",
    FETCH_WITHDRAWAL_CRYPTO_FEE_ERROR = "FETCH_WITHDRAWAL_CRYPTO_FEE_ERROR",
}

interface SetWithdrawalCryptoAction {
    type: WithdrawalCryptoActionTypes.SET_DATA_WITHDRAWAL_CRYPTO;
    payload: IWithdrawalCryptoData
}

interface ClearWithdrawalCryptoAction {
    type: WithdrawalCryptoActionTypes.CLEAR_STATE_WITHDRAWAL_CRYPTO;
}

interface FetchWithdrawalCryptoAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO;
}

interface FetchWithdrawalCryptoSuccessAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_SUCCESS;
    payload: IWithdrawalCryptoResponse;
}

interface FetchWithdrawalCryptoErrorAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_ERROR;
    payload: string;
}

interface ClearWithdrawalCryptoFeeAction {
    type: WithdrawalCryptoActionTypes.CLEAR_WITHDRAWAL_CRYPTO_FEE;
}

interface FetchWithdrawalCryptoFeeAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE;
}

interface FetchWithdrawalCryptoFeeSuccessAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_SUCCESS;
    payload: number;
}

interface FetchWithdrawalCryptoFeeErrorAction {
    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_ERROR;
    payload: string;
}

export type WithdrawalCryptoActions =
    SetWithdrawalCryptoAction
    | ClearWithdrawalCryptoAction
    | FetchWithdrawalCryptoAction
    | FetchWithdrawalCryptoSuccessAction
    | FetchWithdrawalCryptoErrorAction
    | ClearWithdrawalCryptoFeeAction
    | FetchWithdrawalCryptoFeeAction
    | FetchWithdrawalCryptoFeeSuccessAction
    | FetchWithdrawalCryptoFeeErrorAction