import React from 'react';
import styles from './form.module.css';

interface IPropsForm extends React.FormHTMLAttributes<HTMLFormElement>{
}

export function Form({children, ...props}: IPropsForm) {
    return (
        <form
            className={styles.form}
            {...props}
        >
            {children}
        </form>
    );
}