import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {ICardDepositData} from "../../../model/ICardDeposit";
import {CardDepositActions, CardDepositActionTypes} from "../../../types/Fiat/Deposit/CardDeposit";

export const ActionCreatorCardDeposit = {
    setCardDepositData: (data: ICardDepositData) =>  (dispatch: Dispatch<CardDepositActions>) => {
        dispatch({type: CardDepositActionTypes.SET_DATA_CARD_DEPOSIT, payload: data})
    },
    clearCardDepositData: () => (dispatch: Dispatch<CardDepositActions>) => {
        dispatch({type: CardDepositActionTypes.CLEAR_STATE_CARD_DEPOSIT})
    },
    fetchCardDeposit: (data: ICardDepositData) => async (dispatch: Dispatch<CardDepositActions | AuthActions>) => {
        try {
            dispatch({type: CardDepositActionTypes.FETCH_CARD_DEPOSIT})
            const response = await axios.post('expi/deposit', data, {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if(response.data.status > 0) {
                dispatch({
                    type: CardDepositActionTypes.FETCH_CARD_DEPOSIT_ERROR,
                    payload: response.data.message
                })
            } else {
                dispatch({type: CardDepositActionTypes.FETCH_CARD_DEPOSIT_SUCCESS, payload: response.data})
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: CardDepositActionTypes.FETCH_CARD_DEPOSIT_ERROR,
                    payload: `CardDeposit ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR CardDeposit: ", e.response)
            }
        }
    },
}

