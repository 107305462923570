import React from 'react';
import {Link, Typography} from "@mui/material";
import {Colors} from "../../../utils/constants";
import Box from "@mui/material/Box";
// import TermsOfUse from "../../../docs/Terms_of_Use_revised.pdf";
// import CookiePolice from "../../../docs/COOKIE_POLICY.pdf";
// import AMLPolice from "../../../docs/AML_policy.pdf";
// import PersonalOfDataStoragePolice from "../../../docs/Personal_data_storage_policy.pdf";

const Footer = () => {
    return (
        <Box
            sx={{
                px: 3,
                mt: 'auto',
            }}
            component={"footer"}
            width={"100%"}
            display={"flex"}
            flexWrap={"wrap"}
            flexDirection={"column"}
        >
            <Box
                sx={{
                    borderTop: "1px solid #858585",
                }}
                paddingTop={3}
                marginBottom={1}
            >
                <Typography
                    // maxWidth={600}
                    color={Colors.textSecondary}
                    fontSize={14}
                    lineHeight={1}
                >
                    &copy;️2019&mdash;2021&nbsp;BERTES INVEST&nbsp;OU (reg. No&nbsp;12449024) is&nbsp;registered
                    in&nbsp;Estonia with its registration address at&nbsp;Harju maakond, Tallinn, Kesklinna linnaosa,
                    Veskiposti tn&nbsp;2, 10138, Estonia. Bertes Invest OU&nbsp;operates under Financial services,
                    Providing a&nbsp;virtual currency services license (license number: FVT000291), issued
                    by&nbsp;Estonian Financial Intelligence Unit. Contact phone number: +372&nbsp;601 9100;
                    e-mail: <Link
                    href="mailto: support@frtn.trade"
                    color={Colors.primary}
                >
                    support@frtn.trade
                </Link>
                </Typography>
            </Box>
            <Box
                display={"flex"}
                marginBottom={1}
            >
                <Typography
                    color={Colors.textSecondary}
                    fontSize={24}
                    marginRight={3}
                >
                    Contact phone number: <Link
                    href="tel: +3726019100"
                    color={Colors.primary}
                >+372 601 9100
                </Link>
                </Typography>
                <Typography
                    color={Colors.textSecondary}
                    fontSize={24}
                >
                    e-mail: <Link
                    href="mailto: support@evercash.eu"
                    color={Colors.primary}
                >support@evercash.eu
                </Link>
                </Typography>
            </Box>
            {/*<Box*/}
            {/*    display={"flex"}*/}
            {/*    // flexDirection={"column"}*/}
            {/*    marginRight={11}*/}
            {/*    marginBottom={1}*/}
            {/*>*/}
            {/*    <Link*/}
            {/*        href={TermsOfUse}*/}
            {/*        target="_blank"*/}
            {/*        fontSize={24}*/}
            {/*        sx={{*/}
            {/*            marginRight: 3*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Terms of Use*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={CookiePolice}*/}
            {/*        target="_blank"*/}
            {/*        fontSize={24}*/}
            {/*        sx={{*/}
            {/*            marginRight: 3*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Cookie police*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={AMLPolice}*/}
            {/*        target="_blank"*/}
            {/*        fontSize={24}*/}
            {/*        sx={{*/}
            {/*            marginRight: 3*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        AML policy*/}
            {/*    </Link>*/}
            {/*    <Link*/}
            {/*        href={PersonalOfDataStoragePolice}*/}
            {/*        target="_blank"*/}
            {/*        fontSize={24}*/}
            {/*        sx={{*/}
            {/*            marginRight: 3*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Personal Data storage policy*/}
            {/*    </Link>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default Footer;