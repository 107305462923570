import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {CurrencyListActions, CurrencyListActionTypes} from "../../../types/Dict/CurrencyList";


export const ActionCreatorCurrencyList = {
    fetchCurrencyList: () => async (dispatch: Dispatch<CurrencyListActions>) => {
        try {
            dispatch({type: CurrencyListActionTypes.FETCH_CURRENCY_LIST})
            const response = await axios.get('expi/getCurrencyList', {
                baseURL: config.url.API_URL,
            })
            dispatch({type: CurrencyListActionTypes.FETCH_CURRENCY_LIST_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: CurrencyListActionTypes.FETCH_CURRENCY_LIST_ERROR,
                payload: `CurrencyList ERROR: ${JSON.stringify(e.response.data)}`
            })
            console.log("ERROR CurrencyList: ", e.response)
        }
    },
}

