import {HistoryActions, HistoryActionTypes, HistoryState} from "../../../types/User/History";

const initialState: HistoryState = {
    responseHistory: [{
        type: "",
        status: "",
        id: "",
        from: "",
        to: "",
        txId: "",
        note: "",
        amount: 0,
        total: 0,
        currency: "",
        fee: 0,
        paymentSystem: "",
        createdAt: 0,
        updatedAt: 0
    }],
    loading: false,
    error: null
}

export const historyReducer = (state = initialState, action: HistoryActions): HistoryState => {
    switch (action.type) {
            case HistoryActionTypes.FETCH_HISTORY:
            return {...state, loading: true, error: null};
        case HistoryActionTypes.FETCH_HISTORY_SUCCESS:
            return {...state, loading: false, responseHistory: action.payload};
        case HistoryActionTypes.FETCH_HISTORY_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}