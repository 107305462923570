import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AddWalletActions, AddWalletActionTypes} from "../../../types/Crypto/AddWallet";

export const ActionCreatorAddWallet = {
    fetchAddWallet: (currency?: string) => async (dispatch: Dispatch<AddWalletActions>) => {
        try {
            dispatch({type: AddWalletActionTypes.FETCH_ADD_WALLET})
            const response = await axios.get('expi/addWallet', {
                params: {
                  currencyId: currency,
                },
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            dispatch({type: AddWalletActionTypes.FETCH_ADD_WALLET_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: AddWalletActionTypes.FETCH_ADD_WALLET_ERROR,
                payload: `AddWallet ERROR: ${JSON.stringify(e.response.data)}`
            })
            console.log("ERROR AddWallet: ", e.response)
        }
    },
}

