import React from 'react';

export function Eye() {
    return (
        <svg width="22" height="18" id="eoGeSiP48VK1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 22 18" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
            <path
                d="M14.8362,15.754L12.617,13.5334c-.8582.3068-1.7859.3636-2.67517.1638s-1.70348-.648-2.34795-1.2925c-.64447-.6444-1.09269-1.4587-1.29249-2.3479s-.14298-1.815634.16386-2.673854L3.63275,4.54913C1.28975,6.63225,0,9,0,9s4.125,7.5625,11,7.5625c1.3206-.0046,2.6262-.2797,3.8362-.8085v0ZM7.16375,2.246C8.37382,1.71721,9.67944,1.44205,11,1.4375C17.875,1.4375,22,9,22,9s-1.2911,2.3664-3.6314,4.4522L15.5334,10.617c.3068-.85822.3636-1.78592.1638-2.67517s-.648-1.70348-1.2925-2.34795c-.6444-.64447-1.4587-1.09269-2.3479-1.29249-.8893-.1998-1.817-.14298-2.67518.16386L7.16375,2.24737v-.00137Z"
                transform="translate(-.02-.040658)"/>
            <path
                d="M7.59681,8.51324c-.07564.52843-.02717,1.06721.14157,1.57366s.45311.9666.83057,1.3441.83764.6618,1.34409.8305c.50646.1688,1.04526.2173,1.57366.1416-.295845-2.726995-1.236475-6.26166-3.89127-3.88986h.00138Zm6.80629.9735c-2.853255-.224513-6.717655-.923483-3.8899-3.89125.5284-.07563,1.0672-.02716,1.5736.14158s.9667.4531,1.3441.83056c.3775.37747.6618.83765.8306,1.34409s.2172,1.04522.1416,1.57365v.00137Zm.4131,6.226574c-1.08171-.117505-2.026369-1.329913-2.2192-2.2206c1.119389-.285445,2.483048-1.841391,2.9164-2.9164c1.290952.099223,2.799714,1.406669,2.8352,2.8352-.02,0-1.792938,1.328838-3.5324,2.3018Z"
                transform="translate(0 0.000028)"/>
            <path
                d="M7.59681,8.51324c-.07564.52843-.02717,1.06721.14157,1.57366s.45311.9666.83057,1.3441.83764.6618,1.34409.8305c.50646.1688,1.04526.2173,1.57366.1416c2.700881-4.911118.13775-6.100721-3.89127-3.88986h.00138Zm6.80629.9735c-1.756417-.666013-3.41946-2.879521-3.8899-3.89125.5284-.07563,1.0672-.02716,1.5736.14158s.9667.4531,1.3441.83056c.3775.37747.6618.83765.8306,1.34409s.2172,1.04522.1416,1.57365v.00137Zm1.147364-2.145794c-.276268-1.234455-1.800528-2.644401-2.9055-2.91635.316504-.921343.785936-2.219234,2.209604-2.219234.07796-.081149,2.910621,1.549305,3.537765,2.303113-.124608,1.117998-1.637567,2.464541-2.841869,2.832471Z"
                transform="matrix(-1.003741 0 0 1.000007 22.073889-.000035)"/>
        </svg>
    );
}