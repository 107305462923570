import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AddressActions, AddressActionTypes} from "../../../types/Crypto/AddressWallet";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";

export const ActionCreatorAddress = {
    fetchAddress: () => async (dispatch: Dispatch<AddressActions | AuthActions>) => {
        try {
            dispatch({type: AddressActionTypes.FETCH_ADDRESS})
            const response = await axios.get('expi/getAddresses', {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            dispatch({type: AddressActionTypes.FETCH_ADDRESS_SUCCESS, payload: response.data})
        } catch (e: any) {
            if (e.response.status >= 401 && e.response.status < 500) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({type: AddressActionTypes.FETCH_ADDRESS_ERROR, payload: 'Произошла ошибка при загрузки данных!'})
                console.log("ERROR fetchAddress: ", e.response)
            }
        }
    }
}

