import React, {FC} from 'react';
import {Typography} from "@mui/material";

const TitleMain: FC = ({ children }:React.PropsWithChildren<any>) => {
    return (
        <Typography
            variant="h1"
            fontSize={24}
            fontWeight={600}
            marginBottom={3}
        >
            {children}
        </Typography>
    );
};

export default TitleMain;