import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {UserActions, UserActionTypes} from "../../../types/User/User";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";

export const ActionCreatorUser = {
    fetchBalance: () => async (dispatch: Dispatch<UserActions |AuthActions>) => {
        try {
            dispatch({type: UserActionTypes.FETCH_USER})
            const response = await axios.get('expi/getHeaderInfo', {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
                dispatch({type: UserActionTypes.FETCH_USER_SUCCESS, payload: response.data})
        } catch (e: any) {
            if(e.response.status >= 401 && e.response.status < 500){
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else{
                dispatch({type: UserActionTypes.FETCH_USER_ERROR, payload: 'Произошла ошибка при загрузки данных!'})
                console.log("ERROR fetchBalance: ", e.response)
            }
        }
    }
}

