import {IBalance} from "../model/IBalance";
import {ICurrencyList} from "../model/ICurrencyList";

export const getNoOpenWalletsList = (arrOpenWallets: IBalance[], currencyList: ICurrencyList[]) => {
    let noOpenWallets;
    let openWallets = arrOpenWallets.map(wallet => wallet.currency);

    noOpenWallets = currencyList.filter(currency => openWallets.indexOf(currency.id) === -1);

    return noOpenWallets;
};

