import {AddWalletActions, AddWalletActionTypes, AddWalletState} from "../../../types/Crypto/AddWallet";

const initialState: AddWalletState = {
    responseAddWallet: "",
    loading: false,
    error: null
}

export const addWalletReducer = (state = initialState, action: AddWalletActions): AddWalletState => {
    switch (action.type) {
        case AddWalletActionTypes.FETCH_ADD_WALLET:
            return {...state, loading: true, error: null};
        case AddWalletActionTypes.FETCH_ADD_WALLET_SUCCESS:
            return {...state, loading: false, responseAddWallet: action.payload};
        case AddWalletActionTypes.FETCH_ADD_WALLET_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}