import React from 'react';
import styles from './H3.module.css';
interface IH3Props extends React.HTMLAttributes<HTMLParagraphElement>{
}

export function H3({children, ...props}: IH3Props) {
    return (
        <h2
            className={styles.css}
            {...props}
        >
            {children}
        </h2>
    );
}
