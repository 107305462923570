export interface PayPaymentRequestState {
    responsePayPaymentRequest: boolean,
    error: null | string,
    loading: boolean
}

export enum PayPaymentRequestActionTypes {
    CLEAR_PAY_PAYMENT_REQUEST = "CLEAR_PAY_PAYMENT_REQUEST",
    FETCH_PAY_PAYMENT_REQUEST = "FETCH_PAY_PAYMENT_REQUEST",
    FETCH_PAY_PAYMENT_REQUEST_SUCCESS = "FETCH_PAY_PAYMENT_REQUEST_SUCCESS",
    FETCH_PAY_PAYMENT_REQUEST_ERROR = "FETCH_PAY_PAYMENT_REQUEST_ERROR",
}

interface ClearPayPaymentRequestAction {
    type: PayPaymentRequestActionTypes.CLEAR_PAY_PAYMENT_REQUEST;
}

interface FetchPayPaymentRequestAction {
    type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST;
}

interface FetchPayPaymentRequestSuccessAction {
    type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_SUCCESS;
    payload: boolean;
}

interface FetchPayPaymentRequestErrorAction {
    type: PayPaymentRequestActionTypes.FETCH_PAY_PAYMENT_REQUEST_ERROR;
    payload: string;
}

export type PayPaymentRequestActions =
    ClearPayPaymentRequestAction
    | FetchPayPaymentRequestAction
    | FetchPayPaymentRequestSuccessAction
    | FetchPayPaymentRequestErrorAction