import {TransferActions, TransferActionTypes, TransferState} from "../../../../types/Crypto/Transfer";

const initialState: TransferState = {
    responseTransfer: {
        message: ""
    },
    dataTransfer: {
        to: "",
        amount: "",
        currency: "EPI",
        note: ""
    },
    error: null,
    loading: false
}

export const transferReducer = (state = initialState, action: TransferActions): TransferState => {
    switch (action.type) {
        case TransferActionTypes.SET_DATA_TRANSFER:
            return {...state, dataTransfer: action.payload};
        case TransferActionTypes.CLEAR_STATE_TRANSFER:
            return {
                ...state,   responseTransfer: {
                    message: ""
                },
                dataTransfer: {
                    to: "",
                    amount: "",
                    currency: "EPI",
                    note: ""
                },
                error: null,
                loading: false
            };
        case TransferActionTypes.FETCH_TRANSFER:
            return {...state, loading: false, error: null};
        case TransferActionTypes.FETCH_TRANSFER_SUCCESS:
            return {...state, loading: false, responseTransfer: action.payload};
        case TransferActionTypes.FETCH_TRANSFER_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}