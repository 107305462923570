/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {useActions} from "../hooks/useActions";
import {useTypeSelector} from "../hooks/useTypeSelector";

import moment from 'moment';

import {
    Alert,
    Box,
    Button,
    Fade, List, ListItem,
    Modal,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import TitleMain from "../components/UI/TitleMain";
import {Colors, Fonts, ModalStyle} from "../utils/constants";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../types/Route";

interface IdataInfo {
    id: string,
    transactionId: string,
    date: string,
    amount: number,
    merchantName: string,
    redirectUrl: string
}

const PaymentRequest: FC = () => {
    const history = useHistory();
    const {user} = useTypeSelector(state => state.user);
    const errorUser = useTypeSelector(state => state.user.error);
    const {responsePaymentRequestList, error} = useTypeSelector(state => state.paymentRequestList);
    const {invoiceId} = useTypeSelector(state => state.bindPaymentRequest);
    const {responsePayPaymentRequest, loading} = useTypeSelector(state => state.payPaymentRequest);
    const errorPayPaymentRequest = useTypeSelector(state => state.payPaymentRequest.error);
    const [confirmModal, setConfirmModal] = useState({
        open: false,
        info: {
            id: "",
            transactionId: "",
            date: "",
            amount: 0,
            merchantName: "",
            redirectUrl: ""
        }
    });
    const {
        fetchPaymentRequestList,
        fetchPayPaymentRequest,
        clearPayPaymentRequest,
        fetchAddPaymentRequest,
    } = useActions();


    const handlePay = (paymentId: string) => {
        fetchPayPaymentRequest(paymentId)
    };

    const makeDeposit = (amount: number) => {
        history.push(RouteNames.FUNDS + `?amount=${amount}`)
    };

    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        clearPayPaymentRequest();
    };

    const handleOpenModal = (dataInfo: IdataInfo) => {
        handleCloseSnackBar();
        setConfirmModal({
            ...confirmModal,
            open: true,
            info: {
                id: dataInfo.id,
                transactionId: dataInfo.transactionId,
                date: dataInfo.date,
                amount: dataInfo.amount,
                merchantName: dataInfo.merchantName,
                redirectUrl: dataInfo.redirectUrl
            }
        })
    };

    const handleCloseModal = () => {
        setConfirmModal({
            open: false,
            info: {
                id: "",
                transactionId: "",
                date: "",
                amount: 0,
                merchantName: "",
                redirectUrl: ""
            }
        })
    };

    useEffect(() => {
        if (invoiceId) {
            fetchAddPaymentRequest(invoiceId)
        }
        fetchPaymentRequestList()
        handleCloseModal();
        handleCloseSnackBar();
    }, []);

    useEffect(() => {
        if (responsePayPaymentRequest) {
            handleCloseModal();
            fetchPaymentRequestList();
            if (confirmModal.info.redirectUrl) {
                window.open(confirmModal.info.redirectUrl, '_blank')
            }
        }
    }, [responsePayPaymentRequest]);

    if (error || errorUser || errorPayPaymentRequest) {
        return <Typography>{error || errorUser || errorPayPaymentRequest}</Typography>
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >
            <Snackbar open={responsePayPaymentRequest} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{width: '100%'}}>
                    Payment requests success!
                </Alert>
            </Snackbar>
            <TitleMain>
                PAYMENT REQUEST
            </TitleMain>
            <Typography
                maxWidth="fit-content"
                variant="h2"
                fontSize={18}
                fontWeight={400}
                marginBottom={3}
                color={Colors.textSecondary}
            >
                {`Your EuPI balance: `} <span className={"icon-eupi_symbol"} style={{
                fontSize: 15,
                fontFamily: "Icon"
            }}/> {user.balanceList.find(wallet => wallet.currency === "EPI" && wallet)?.balance}
            </Typography>
            <Stack

                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={0}
            >
                <List
                    sx={{
                        width: '100%',
                        minWidth: 390,
                        maxWidth: 460,
                        bgcolor: 'background.paper',
                        paddingTop: 0,
                    }}
                >
                    <ListItem
                        sx={{bgcolor: Colors.paymentListTitle}}
                    >
                        <Typography
                            paddingY={"4px"}
                            fontWeight={400}
                            fontSize={18}
                            lineHeight={'21px'}
                            color={"#fff"}
                        >
                            Your payment requests
                        </Typography>
                    </ListItem>
                    {responsePaymentRequestList.map(payment =>
                        payment.status.final ?
                            null
                            :
                        <ListItem
                            key={payment.id}
                            sx={{
                                paddingY: 2,
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                border: `1px solid ${Colors.tableHeader}`
                            }}
                        >
                            <Box
                                width={"100%"}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <Box>
                                    <Typography
                                        marginBottom={"10px"}
                                        fontWeight={400}
                                        fontSize={12}
                                        lineHeight={'14px'}
                                        color={Colors.textSecondary}
                                    >
                                        {`ID: ${payment.transactionId}`}
                                    </Typography>
                                    <Typography
                                        // lineHeight={"21px"}
                                        fontWeight={400}
                                        fontSize={18}
                                        color={Colors.textPrimary}
                                    >
                                        {`${payment.amount} EUPi`}
                                    </Typography>
                                    <Typography
                                        lineHeight={"14px"}
                                        fontWeight={400}
                                        fontSize={12.5}
                                        color={Colors.textPrimary}
                                    >
                                        {payment.merchantName}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        // marginBottom={"15px"}
                                        fontWeight={400}
                                        fontSize={12.5}
                                        color={Colors.textSecondary}
                                        lineHeight={'14px'}
                                    >
                                        {`Date: ${moment(payment.date).format("DD.mm.YYYY")}`}
                                    </Typography>
                                    {
                                        payment.readyToPay &&
                                        <Button
                                            onClick={
                                                handleOpenModal.bind(
                                                    null,
                                                    {
                                                        id: payment.id,
                                                        transactionId: payment.transactionId,
                                                        merchantName: payment.merchantName,
                                                        amount: payment.amount,
                                                        date: moment(payment.date).format("DD.mm.YYYY"),
                                                        redirectUrl: payment.redirectUrl
                                                    }
                                                )
                                            }
                                            disabled={!payment.readyToPay}
                                            fullWidth
                                            variant={"contained"}
                                        >
                                            PAY NOW
                                        </Button>
                                    }
                                </Box>
                            </Box>
                            {
                                !payment.readyToPay &&
                                <Box
                                    marginTop={"10px"}
                                >
                                    <Typography
                                        lineHeight={"18px"}
                                        fontWeight={400}
                                        fontSize={16}
                                        color={Colors.textInfo}
                                        marginBottom={"25px"}
                                    >
                                        Unfortunately, there are not enough funds on the account for payment!
                                    </Typography>
                                    <Button
                                        onClick={makeDeposit.bind(null, payment.amount)}
                                        sx={{
                                            width: "12pc"
                                        }}
                                        variant={"contained"}
                                    >
                                        Make a deposit
                                    </Button>
                                </Box>
                            }
                        </ListItem>
                    )}
                </List>
            </Stack>
            {/*CONFIRM PAY REQUEST MODAL=======================================================================================*/}
            <Modal
                aria-labelledby="confirm-payment-request-modal-title"
                aria-describedby="confirm-payment-request-modal-description"
                open={confirmModal.open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade
                    in={confirmModal.open}
                >
                    <Box
                        sx={ModalStyle}
                    >
                        <Typography
                            id="withdrawal-crypto-order-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {confirmModal.info.merchantName}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-account-id"
                            variant="body2"
                            fontWeight={400}
                            fontSize={16}
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Transaction ID: ${confirmModal.info.transactionId}`}
                        </Typography>
                        <Typography
                            id="exchange-order-modal-order-id"
                            variant="body2"
                            fontWeight={400}
                            fontSize={16}
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Creation date: ${confirmModal.info.date}`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-sent"
                            variant="body2"
                            fontWeight={400}
                            fontSize={16}
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Amount: ${confirmModal.info.amount} EUPi`}
                        </Typography>
                        <LoadingButton
                            sx={{
                                width: "100%"
                            }}
                            loading={loading}
                            variant={"contained"}
                            onClick={handlePay.bind(null, confirmModal.info.id)}
                        >
                            Confirm
                        </LoadingButton>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default PaymentRequest;