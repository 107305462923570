export interface IAuthState {
    auth: boolean;
    OTPSent: boolean;
    token: string;
    phone: string;
    status: number;
    password: string;
    errorOTP: null | string;
    errorAuth: null | string;
    loading: boolean;
}

export enum AuthActionTypes {
    SET_AUTH = "SET_AUTH",
    SET_LOADING = "SET_LOADING",
    SET_PHONE = "SET_PHONE",
    SET_PASSWORD = "SET_PASSWORD",
    FETCH_OTP = "FETCH_OTP",
    FETCH_OTP_SUCCESS = "FETCH_OTP_SUCCESS",
    FETCH_OTP_ERROR = "FETCH_OTP_ERROR",
    FETCH_AUTH = "FETCH_AUTH",
    FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS",
    FETCH_AUTH_ERROR = "FETCH_AUTH_ERROR",
}

interface SetAuthAction {
    type: AuthActionTypes.SET_AUTH;
    payload: boolean;
}

interface SetLoadingAction {
    type: AuthActionTypes.SET_LOADING;
    payload: boolean;
}

interface SetPhoneAction {
    type: AuthActionTypes.SET_PHONE;
    payload: string;
}

interface SetPasswordAction {
    type: AuthActionTypes.SET_PASSWORD;
    payload: string;
}

interface FetchOTPAction {
    type: AuthActionTypes.FETCH_OTP;
}

interface FetchOTPSuccessAction {
    type: AuthActionTypes.FETCH_OTP_SUCCESS;
}

interface FetchOTPErrorAction {
    type: AuthActionTypes.FETCH_OTP_ERROR;
    payload: string,
}
interface FetchAuthAction {
    type: AuthActionTypes.FETCH_AUTH;
}

interface FetchAuthSuccessAction {
    type: AuthActionTypes.FETCH_AUTH_SUCCESS;
    payload: string;
}

interface FetchAuthErrorAction {
    type: AuthActionTypes.FETCH_AUTH_ERROR;
    payload: string
}

export type AuthActions =
    SetAuthAction
    | SetLoadingAction
    | SetPhoneAction
    | SetPasswordAction
    | FetchOTPAction
    | FetchOTPSuccessAction
    | FetchOTPErrorAction
    | FetchAuthAction
    | FetchAuthSuccessAction
    | FetchAuthErrorAction