import {ITransferData, ITransferResponse} from "../../model/Transfer";


export interface TransferState {
    responseTransfer: ITransferResponse,
    dataTransfer: ITransferData,
    error: null | string,
    loading: boolean
}

export enum TransferActionTypes {
    SET_DATA_TRANSFER = "SET_DATA_TRANSFER",
    CLEAR_STATE_TRANSFER = "CLEAR_STATE_TRANSFER",
    FETCH_TRANSFER = "FETCH_TRANSFER",
    FETCH_TRANSFER_SUCCESS = "FETCH_TRANSFER_SUCCESS",
    FETCH_TRANSFER_ERROR = "FETCH_TRANSFER_ERROR",
}

interface SetTransferAction {
    type: TransferActionTypes.SET_DATA_TRANSFER;
    payload: ITransferData
}

interface ClearTransferAction {
    type: TransferActionTypes.CLEAR_STATE_TRANSFER;
}

interface FetchTransferAction {
    type: TransferActionTypes.FETCH_TRANSFER;
}

interface FetchTransferSuccessAction {
    type: TransferActionTypes.FETCH_TRANSFER_SUCCESS;
    payload: ITransferResponse;
}

interface FetchTransferErrorAction {
    type: TransferActionTypes.FETCH_TRANSFER_ERROR;
    payload: string;
}


export type TransferActions =
    SetTransferAction
    | ClearTransferAction
    | FetchTransferAction
    | FetchTransferSuccessAction
    | FetchTransferErrorAction
