import {CardDepositActions, CardDepositActionTypes, CardDepositState} from "../../../../types/Fiat/Deposit/CardDeposit";
import {config} from "../../../../utils/config";

const initialState: CardDepositState = {
    responseCardDeposit: {
        status: 10,
        message: "",
        redirectUrl: ""
    },
    dataCardDeposit: {
        amount: "",
        callbackUrl: config.url.REDIRECT
    },
    error: null,
    loading: false
}

export const cardDepositReducer = (state = initialState, action: CardDepositActions): CardDepositState => {
    switch (action.type) {
        case CardDepositActionTypes.SET_DATA_CARD_DEPOSIT:
            return {...state, dataCardDeposit: action.payload};
        case CardDepositActionTypes.CLEAR_STATE_CARD_DEPOSIT:
            return {
                ...state,
                responseCardDeposit: {
                    status: 10,
                    message: "",
                    redirectUrl: ""
                },
                dataCardDeposit: {
                    amount: "",
                    callbackUrl: config.url.REDIRECT
                },
                error: null,
                loading: false
            };
        case CardDepositActionTypes.FETCH_CARD_DEPOSIT:
            return {...state, loading: false, error: null};
        case CardDepositActionTypes.FETCH_CARD_DEPOSIT_SUCCESS:
            return {...state, loading: false, responseCardDeposit: action.payload};
        case CardDepositActionTypes.FETCH_CARD_DEPOSIT_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}