import {CurrencyListActions, CurrencyListActionTypes, CurrencyListState} from "../../../types/Dict/CurrencyList";

const initialState: CurrencyListState = {
    responseCurrencyList: [
        {
            id: "",
            fiat: false,
            crypto: false,
            token: false
        }
    ],
    loading: false,
    error: null
}

export const currencyListReducer = (state = initialState, action: CurrencyListActions): CurrencyListState => {
    switch (action.type) {
        case CurrencyListActionTypes.FETCH_CURRENCY_LIST:
            return {...state, loading: true, error: null};
        case CurrencyListActionTypes.FETCH_CURRENCY_LIST_SUCCESS:
            return {...state, loading: false, responseCurrencyList: action.payload};
        case CurrencyListActionTypes.FETCH_CURRENCY_LIST_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}