import {IAddressWallet} from "../../model/IAddressWallet";

export interface AddressWalletState {
    walletAddress: IAddressWallet[],
    error: null | string,
    loading: boolean,
}

export enum AddressActionTypes {
    FETCH_ADDRESS = "FETCH_ADDRESS",
    FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS",
    FETCH_ADDRESS_ERROR = "FETCH_ADDRESS_ERROR",
}

interface FetchAddressAction {
    type: AddressActionTypes.FETCH_ADDRESS;
}

interface FetchAddressSuccessAction {
    type: AddressActionTypes.FETCH_ADDRESS_SUCCESS;
    payload: IAddressWallet[];
}

interface FetchAddressErrorAction {
    type: AddressActionTypes.FETCH_ADDRESS_ERROR;
    payload: string;
}

export type AddressActions =
    FetchAddressAction
    | FetchAddressSuccessAction
    | FetchAddressErrorAction