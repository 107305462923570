import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {WithdrawalCryptoActions, WithdrawalCryptoActionTypes} from "../../../types/Crypto/WithdrawalCrypto";
import {IWithdrawalCryptoData} from "../../../model/IWithdrawalCryptoData";

export const ActionCreatorWithdrawalCrypto = {
    setWithdrawalCryptoData: (data: IWithdrawalCryptoData) => async (dispatch: Dispatch<WithdrawalCryptoActions>) => {
        dispatch({type: WithdrawalCryptoActionTypes.SET_DATA_WITHDRAWAL_CRYPTO, payload: data})
    },
    clearWithdrawalCryptoData: () => async (dispatch: Dispatch<WithdrawalCryptoActions>) => {
        dispatch({type: WithdrawalCryptoActionTypes.CLEAR_STATE_WITHDRAWAL_CRYPTO})
    },
    fetchWithdrawalCrypto: (data: IWithdrawalCryptoData) => async (dispatch: Dispatch<WithdrawalCryptoActions | AuthActions>) => {
        try {
            dispatch({type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO})
            const response = await axios.post('expi/cryptoWithdraw', data, {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if (response.data.result) {
                dispatch({
                    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_ERROR,
                    payload: response.data.desc
                })
            } else {
                dispatch({type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_SUCCESS, payload: response.data})

            }
        } catch (e: any) {
            if (e.response.status >= 401 && e.response.status < 500) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_ERROR,
                    payload: 'Произошла ошибка при загрузки данных!'
                })
                console.log("ERROR WithdrawalCrypto: ", e.response)
            }
        }
    },
    fetchWithdrawalCryptoFee: (amount: number, currency: string) => async (dispatch: Dispatch<WithdrawalCryptoActions | AuthActions>) => {
        try {
            dispatch({type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE})
            const response = await axios.get('expi/withdrawalFee', {
                params: {amount: amount, currency: currency},
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            dispatch({type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_SUCCESS, payload: response.data})
        } catch (e: any) {
            if (e.response.status >= 401 && e.response.status < 500) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_ERROR,
                    payload: 'Произошла ошибка при загрузки данных!'
                })
                console.log("ERROR WithdrawalCrypto: ", e.response)
            }
        }
    },

    clearWithdrawalCryptoFee: () => (dispatch: Dispatch<WithdrawalCryptoActions>) => {
        dispatch({type: WithdrawalCryptoActionTypes.CLEAR_WITHDRAWAL_CRYPTO_FEE})
    },
}

