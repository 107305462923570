import {ICurrencyList} from "../../model/ICurrencyList";

export interface CurrencyListState {
    responseCurrencyList: ICurrencyList[],
    error: null | string,
    loading: boolean
}

export enum CurrencyListActionTypes {
    FETCH_CURRENCY_LIST = "FETCH_CURRENCY_LIST",
    FETCH_CURRENCY_LIST_SUCCESS = "FETCH_CURRENCY_LIST_SUCCESS",
    FETCH_CURRENCY_LIST_ERROR = "FETCH_CURRENCY_LIST_ERROR",
}

interface FetchCurrencyListAction {
    type: CurrencyListActionTypes.FETCH_CURRENCY_LIST;
}

interface FetchCurrencyListSuccessAction {
    type: CurrencyListActionTypes.FETCH_CURRENCY_LIST_SUCCESS;
    payload: ICurrencyList[];
}

interface FetchCurrencyListErrorAction {
    type: CurrencyListActionTypes.FETCH_CURRENCY_LIST_ERROR;
    payload: string;
}

export type CurrencyListActions =
    FetchCurrencyListAction
    | FetchCurrencyListSuccessAction
    | FetchCurrencyListErrorAction