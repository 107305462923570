import {IRoute, RouteNames} from "../types/Route";

import Login from "../pages/publick/Login";
import Balance from "../pages/Balance";
import Funds from "../pages/Funds";
import Transfer from "../pages/Transfer";
import Exchange from "../pages/Exchange";
import History from "../pages/History";
import PaymentRequest from "../pages/PaymentRequest";
import Support from "../pages/Support";
import SingUp from "../pages/publick/SingUp";
import Confirm from "../pages/publick/Confirm";

export const publicRoutes: IRoute[] = [
    {path: RouteNames.LOGIN, exact: true, component: Login},
    {path: RouteNames.SING_UP, exact: true, component: SingUp},
    {path: RouteNames.CONFIRM, exact: true, component: Confirm}
];

export const privateRoutes: IRoute[] = [
    {path: RouteNames.BALANCE, exact: true, component: Balance},
    {path: RouteNames.FUNDS, exact: true, component: Funds},
    {path: RouteNames.TRANSFER, exact: true, component: Transfer},
    {path: RouteNames.EXCHANGE, exact: true, component: Exchange},
    {path: RouteNames.EXCHANGE_PARAMS + ":currency", exact: false, component: Exchange},
    {path: RouteNames.HISTORY, exact: true, component: History},
    {path: RouteNames.PAYMENT_REQUEST, exact: true, component: PaymentRequest},
    {path: RouteNames.SUPPORT, exact: true, component: Support},
];

