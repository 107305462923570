import {FiatWithdrawalActions, FiatWithdrawalActionTypes, FiatWithdrawalState} from "../../../../types/Fiat/Withdrawal/FiatWithdarawal";

const initialState: FiatWithdrawalState = {
    responseFiatWithdrawal: {
        status: 10,
        message: ""
    },
    dataFiatWithdrawal: {
        amount: "",
        iban: "",
        swiftCode: ""
    },
    error: null,
    loading: false
}

export const fiatWithdrawalReducer = (state = initialState, action: FiatWithdrawalActions): FiatWithdrawalState => {
    switch (action.type) {
        case FiatWithdrawalActionTypes.SET_DATA_FIAT_WITHDRAWAL:
            return {...state, dataFiatWithdrawal: action.payload};
        case FiatWithdrawalActionTypes.CLEAR_STATE_FIAT_WITHDRAWAL:
            return {
                ...state,   responseFiatWithdrawal: {
                    status: 10,
                    message: ""
                },
                dataFiatWithdrawal: {
                    amount: "",
                    iban: "",
                    swiftCode: ""
                }
            };
        case FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL:
            return {...state, loading: false, error: null};
        case FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_SUCCESS:
            return {...state, loading: false, responseFiatWithdrawal: action.payload};
        case FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}