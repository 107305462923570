export interface FiatWithdrawalFeeState {
    responseFiatWithdrawalFee: number,
    error: null | string,
    loading: boolean
}

export enum FiatWithdrawalFeeActionTypes {
    CLEAR_FIAT_WITHDRAWAL_FEE = "CLEAR_FIAT_WITHDRAWAL_FEE",
    FETCH_FIAT_WITHDRAWAL_FEE = "FETCH_FIAT_WITHDRAWAL_FEE",
    FETCH_FIAT_WITHDRAWAL_FEE_SUCCESS = "FETCH_FIAT_WITHDRAWAL_FEE_SUCCESS",
    FETCH_FIAT_WITHDRAWAL_FEE_ERROR = "FETCH_FIAT_WITHDRAWAL_FEE_ERROR",
}



interface ClearFiatWithdrawalFeeAction {
    type: FiatWithdrawalFeeActionTypes.CLEAR_FIAT_WITHDRAWAL_FEE;
}

interface FetchFiatWithdrawalFeeAction {
    type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE;
}

interface FetchFiatWithdrawalFeeSuccessAction {
    type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_SUCCESS;
    payload: number;
}

interface FetchFiatWithdrawalFeeErrorAction {
    type: FiatWithdrawalFeeActionTypes.FETCH_FIAT_WITHDRAWAL_FEE_ERROR;
    payload: string;
}

export type FiatWithdrawalFeeActions =

    ClearFiatWithdrawalFeeAction
    | FetchFiatWithdrawalFeeAction
    | FetchFiatWithdrawalFeeSuccessAction
    | FetchFiatWithdrawalFeeErrorAction