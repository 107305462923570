import {IExchangeData, IExchangeResponse} from "../../../model/IExchange";


export interface ExchangeState {
    responseExchange: IExchangeResponse,
    dataExchange: IExchangeData,
    error: null | string,
    loading: boolean
}

export enum ExchangeActionTypes {
    SET_DATA_EXCHANGE = "SET_DATA_EXCHANGE",
    CLEAR_STATE_EXCHANGE = "CLEAR_STATE_EXCHANGE",
    FETCH_EXCHANGE = "FETCH_EXCHANGE",
    FETCH_EXCHANGE_SUCCESS = "FETCH_EXCHANGE_SUCCESS",
    FETCH_EXCHANGE_ERROR = "FETCH_EXCHANGE_ERROR",
}

interface SetExchangeAction {
    type: ExchangeActionTypes.SET_DATA_EXCHANGE;
    payload: IExchangeData
}

interface ClearExchangeAction {
    type: ExchangeActionTypes.CLEAR_STATE_EXCHANGE;
}

interface FetchExchangeAction {
    type: ExchangeActionTypes.FETCH_EXCHANGE;
}

interface FetchExchangeSuccessAction {
    type: ExchangeActionTypes.FETCH_EXCHANGE_SUCCESS;
    payload: IExchangeResponse;
}

interface FetchExchangeErrorAction {
    type: ExchangeActionTypes.FETCH_EXCHANGE_ERROR;
    payload: string;
}


export type ExchangeActions =
    SetExchangeAction
    | ClearExchangeAction
    | FetchExchangeAction
    | FetchExchangeSuccessAction
    | FetchExchangeErrorAction
