import React, {FC} from 'react';
import {Box, Link, Typography} from "@mui/material";
import TitleMain from "../components/UI/TitleMain";
import {Colors} from "../utils/constants";
import TermsOfUse from "../docs/Terms_of_Use_revised.pdf";
import CookiePolice from "../docs/COOKIE_POLICY.pdf";
import AMLPolice from "../docs/AML_policy.pdf";
import PersonalOfDataStoragePolice from "../docs/Personal_data_storage_policy.pdf";

const Support: FC = () => {

    // useEffect(() => {
    //     (function (w, d, u) {
    //         const s = d.createElement('script');
    //         s.async = true;
    //         s.src = u + '?' + (Date.now() / 180000 | 0);
    //         const h: any = d.getElementsByTagName('script')[0];
    //         h.parentNode.insertBefore(s, h);
    //     })(window, document, 'https://bitrix.esterna-holding.com/upload/crm/form/loader_28_ce6h5v.js');
    // }, [])

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >

            <TitleMain>
                SUPPORT
            </TitleMain>

            <Typography
                maxWidth={600}
                marginBottom={3}
                color={Colors.textSecondary}
                fontSize={14}
                lineHeight={1}
            >
                ©️2019—2021 BERTES INVEST OU (reg. No 12449024) is registered in Estonia with its registration address
                at Harju maakond, Tallinn, Kesklinna linnaosa, Veskiposti tn 2, 10138, Estonia. Bertes Invest OU
                operates under Financial services, Providing a virtual currency services license (license number:
                FVT000291), issued by Estonian Financial Intelligence Unit. Contact phone number: +372 601 9100;
                e-mail: <Link
                href="mailto: support@evercash.trade"
                color={Colors.primary}
            >
                support@evercash.trade
            </Link>
            </Typography>

            <Typography
                color={Colors.textPrimary}
                fontSize={20}
                marginBottom={2}
            >
                Legal documents:
            </Typography>
            <Box
                width="100%"
                display="flex"
                marginBottom={3}
            >
                <Link
                    href={TermsOfUse}
                    target="_blank"
                    color={Colors.textSecondary}
                    fontSize={14}
                    sx={{
                        "&:hover": {
                            textDecorationColor: Colors.primary,
                            color: Colors.primary,
                        },
                        marginRight: 3
                    }}
                >
                    Terms of Use
                </Link>
                <Link
                    href={CookiePolice}
                    target="_blank"
                    color={Colors.textSecondary}
                    fontSize={14}
                    sx={{
                        "&:hover": {
                            textDecorationColor: Colors.primary,
                            color: Colors.primary,
                        },
                        marginRight: 3
                    }}
                >
                    Cookie police
                </Link>
                <Link
                    href={AMLPolice}
                    target="_blank"
                    color={Colors.textSecondary}
                    fontSize={14}
                    sx={{
                        "&:hover": {
                            textDecorationColor: Colors.primary,
                            color: Colors.primary,
                        },
                        marginRight: 3
                    }}
                >
                    AML policy
                </Link>
                <Link
                    href={PersonalOfDataStoragePolice}
                    target="_blank"
                    color={Colors.textSecondary}
                    fontSize={14}
                    sx={{
                        "&:hover": {
                            textDecorationColor: Colors.primary,
                            color: Colors.primary,
                        }
                    }}
                >
                    Personal Data storage policy
                </Link>
            </Box>

            <script data-b24-form="inline/28/ce6h5v"
                    data-skip-moving="true"/>
        </Box>
    );
};

export default Support;