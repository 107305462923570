import React, {ReactNode} from 'react';
import styles from './input.module.css';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string,
    error?: boolean,
    adornment?: ReactNode,
    onClick?: () => void;
}

export function Input({title, id, error, adornment, onClick, ...props}: IInputProps) {
    return (
            <div className={styles.inputBox}>
                <input
                    className={adornment ? styles.inputPaddingAdornment : styles.input}
                    style={error ? {borderColor: "#DF2929"} : {}}
                    {...props}
                />

                {
                    adornment &&
                    <div
                        className={styles.adornment}
                        onClick={onClick}
                    >{adornment}</div>
                }
            </div>
    );
}