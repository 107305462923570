import {UserState, UserActions, UserActionTypes} from "../../../types/User/User";

const initialState: UserState = {
    user: {
        totalBalance: 0,
        balanceList: [{
            currency: "",
            name: "",
            account: "",
            balance: 0,
            eurRate: 0,
            balanceInEur: 0,
        }],
        id: "",
        firstName: "",
        lastName: "",
    },
    error: null,
    loading: true,
}

export const userReducer = (state = initialState, action: UserActions): UserState => {
    switch (action.type) {
        case UserActionTypes.FETCH_USER:
            return {...state, loading: true};
        case UserActionTypes.FETCH_USER_SUCCESS:
            return {...state, loading: false, user: action.payload};
        case UserActionTypes.FETCH_USER_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}