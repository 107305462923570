import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {FiatDepositInfoActions, FiatDepositInfoActionTypes} from "../../../types/Fiat/Deposit/FiatDeposittInfo";

export const ActionCreatorFiatDepositInfo = {
    fetchFiatDepositInfo: () => async (dispatch: Dispatch<FiatDepositInfoActions | AuthActions>) => {
        try {
            dispatch({type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO})
            const response = await axios.get('expi/getFiatDepositInfo', {
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            dispatch({type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_SUCCESS, payload: response.data})
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: FiatDepositInfoActionTypes.FETCH_FIAT_DEPOSIT_INFO_ERROR,
                    payload: `FiatDepositInfo ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR FiatDepositInfo: ", e.response)
            }
        }
    },
}

