import React from 'react';
import styles from './PrimaryLink.module.css';

interface IPrimaryLink extends React.AnchorHTMLAttributes<HTMLAnchorElement>{
}

export function PrimaryLink({children, ...props}: IPrimaryLink) {
    return (
        <a
            className={styles.css}
            {...props}
        >
            {children}
        </a>
    );
}
