import {ExchangeRateActions, ExchangeRateActionTypes, ExchangeRateState} from "../../../../types/Crypto/Exchange/ExchangeRate";

const initialState: ExchangeRateState = {
    responseRate: {
        credit: 0,
        desc: "",
        rate: 0,
        status: 0,
    },
    loading: false,
    error: null
}

export const exchangeRateReducer = (state = initialState, action: ExchangeRateActions): ExchangeRateState => {
    switch (action.type) {
        case ExchangeRateActionTypes.CLEAR_RATE:
            return {...state,
                responseRate: {
                    credit: 0,
                    desc: "",
                    rate: 0,
                    status: 0,
                },
                loading: false,
                error: null
            };
            case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE:
            return {...state, loading: true, error: null};
        case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_SUCCESS:
            return {...state, loading: false, responseRate: action.payload};
        case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}