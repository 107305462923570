import {ICardDepositData, ICardDepositResponse} from "../../../model/ICardDeposit";

export interface CardDepositState {
    responseCardDeposit: ICardDepositResponse,
    dataCardDeposit: ICardDepositData,
    error: null | string,
    loading: boolean
}

export enum CardDepositActionTypes {
    SET_DATA_CARD_DEPOSIT = "SET_DATA_CARD_DEPOSIT",
    CLEAR_STATE_CARD_DEPOSIT = "CLEAR_STATE_CARD_DEPOSIT",
    FETCH_CARD_DEPOSIT = "FETCH_CARD_DEPOSIT",
    FETCH_CARD_DEPOSIT_SUCCESS = "FETCH_CARD_DEPOSIT_SUCCESS",
    FETCH_CARD_DEPOSIT_ERROR = "FETCH_CARD_DEPOSIT_ERROR",
}

interface SetCardDepositAction {
    type: CardDepositActionTypes.SET_DATA_CARD_DEPOSIT
    payload: ICardDepositData
}

interface ClearCardDepositAction {
    type: CardDepositActionTypes.CLEAR_STATE_CARD_DEPOSIT;
}

interface FetchCardDepositAction {
    type: CardDepositActionTypes.FETCH_CARD_DEPOSIT;
}

interface FetchCardDepositSuccessAction {
    type: CardDepositActionTypes.FETCH_CARD_DEPOSIT_SUCCESS;
    payload: ICardDepositResponse;
}

interface FetchCardDepositErrorAction {
    type: CardDepositActionTypes.FETCH_CARD_DEPOSIT_ERROR;
    payload: string;
}


export type CardDepositActions =
    SetCardDepositAction
    | ClearCardDepositAction
    | FetchCardDepositAction
    | FetchCardDepositSuccessAction
    | FetchCardDepositErrorAction
