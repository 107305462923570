import {IRateResponse} from "../../../model/IRate";


export interface ExchangeRateState {
    responseRate: IRateResponse,
    error: null | string,
    loading: boolean
}

export enum ExchangeRateActionTypes {
    CLEAR_RATE = "CLEAR_RATE",
    FETCH_EXCHANGE_RATE = "FETCH_EXCHANGE_RATE",
    FETCH_EXCHANGE_RATE_SUCCESS = "FETCH_EXCHANGE_RATE_SUCCESS",
    FETCH_EXCHANGE_RATE_ERROR = "FETCH_EXCHANGE_RATE_ERROR",
}



interface ClearRateAction {
    type: ExchangeRateActionTypes.CLEAR_RATE;
}

interface FetchExchangeRateAction {
    type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE;
}

interface FetchExchangeRateSuccessAction {
    type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_SUCCESS;
    payload: IRateResponse;
}

interface FetchExchangeRateErrorAction {
    type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_ERROR;
    payload: string;
}

export type ExchangeRateActions =

    ClearRateAction
    | FetchExchangeRateAction
    | FetchExchangeRateSuccessAction
    | FetchExchangeRateErrorAction