import React, {FC, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {RouteNames} from '../../types/Route';

import {COLORS} from '../../utils/styles';
import {H2} from '../../components/UI/Typography/H2';
import {PrimaryLink} from '../../components/UI/Links';
import {ButtonPrimary} from '../../components/UI/Buttons/ButtonPrimary';
import {Input} from '../../components/UI/Input';
import {Field} from '../../components/UI/Form/Field';
import {Form} from '../../components/UI/Form';
import {Eye, EyeSlash} from '../../Icons';
import {FlexBox} from '../../components/UI/FlexBox';
import { H3 } from '../../components/UI/Typography/H3';

import googlePlay from '../../assets/img/google-play.svg';
import appStore from '../../assets/img/app-store.svg';

const SingUp: FC = () => {
    const history = useHistory();

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const goToLogin = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(RouteNames.LOGIN);
    };

    const submit = (e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("Next step registration");
        history.push(RouteNames.CONFIRM);
    };

    return (
        <React.Fragment>
            <H3
                style={{color: COLORS.textSecondary}}
            >
                START FOR FREE
            </H3>
            <H2>
                Create an&nbsp;account
            </H2>
            <PrimaryLink
                style={{
                    display: "inline-block",
                    marginBottom: "32px"
            }}
                onClick={goToLogin}
            >
                Already have an&nbsp;account?
            </PrimaryLink>

            <Form
                style={{marginBottom: "40px"}}
                onSubmit={submit}
            >
                <Field
                    title={"Phone"}
                    id={"phone"}
                >
                    <Input
                        placeholder={"+x(xxx)xxx-xx-xx"}
                        id={"phone"}
                        type={"tel"}
                    />
                </Field>
                <Field
                    title={"Email"}
                    id={"email"}
                >
                    <Input
                        placeholder={"Email"}
                        id={"email"}
                        type={"email"}
                    />
                </Field>
                <Field
                    title={"Name"}
                    id={"name"}
                >
                    <Input
                        placeholder={"Name"}
                        id={"name"}
                        type={"text"}
                    />
                </Field>
                <Field
                    title={"Surname"}
                    id={"surname"}
                >
                    <Input
                        placeholder={"Surname"}
                        id={"surname"}
                        type={"text"}
                    />
                </Field>
                <Field
                    title={"Password"}
                    id={"password"}
                >
                    <Input
                        id={"password"}
                        type={showPassword ? "test" : "password"}
                        placeholder={"8+ Characters, 1 Capital letter"}
                        adornment={showPassword ? <Eye/> : <EyeSlash/>}
                        onClick={handleShowPassword}
                    />
                </Field>
                <ButtonPrimary
                    title={"Next step"}
                    type={"submit"}
                />
            </Form>
            <FlexBox>
                <PrimaryLink
                    style={{marginRight: "16px"}}
                    href={"#"}
                    target={"_blank"}
                >
                    <img src={googlePlay} alt="Google play"/>
                </PrimaryLink>
                <PrimaryLink
                    href={"#"}
                    target={"_blank"}
                >
                    <img src={appStore} alt="App store"/>
                </PrimaryLink>
            </FlexBox>
        </React.Fragment>
    );
};

export default SingUp;