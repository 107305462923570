import {IFiatWithdrawalData, IFiatWithdrawalResponse} from "../../../model/IFiatWithdrawal";


export interface FiatWithdrawalState {
    responseFiatWithdrawal: IFiatWithdrawalResponse,
    dataFiatWithdrawal: IFiatWithdrawalData,
    error: null | string,
    loading: boolean
}

export enum FiatWithdrawalActionTypes {
    SET_DATA_FIAT_WITHDRAWAL = "SET_DATA_FIAT_WITHDRAWAL",
    CLEAR_STATE_FIAT_WITHDRAWAL = "CLEAR_STATE_FIAT_WITHDRAWAL",
    FETCH_FIAT_WITHDRAWAL = "FETCH_FIAT_WITHDRAWAL",
    FETCH_FIAT_WITHDRAWAL_SUCCESS = "FETCH_FIAT_WITHDRAWAL_SUCCESS",
    FETCH_FIAT_WITHDRAWAL_ERROR = "FETCH_FIAT_WITHDRAWAL_ERROR",
}

interface SetFiatWithdrawalAction {
    type: FiatWithdrawalActionTypes.SET_DATA_FIAT_WITHDRAWAL;
    payload: IFiatWithdrawalData
}

interface ClearFiatWithdrawalAction {
    type: FiatWithdrawalActionTypes.CLEAR_STATE_FIAT_WITHDRAWAL;
}

interface FetchFiatWithdrawalAction {
    type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL;
}

interface FetchFiatWithdrawalSuccessAction {
    type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_SUCCESS;
    payload: IFiatWithdrawalResponse;
}

interface FetchFiatWithdrawalErrorAction {
    type: FiatWithdrawalActionTypes.FETCH_FIAT_WITHDRAWAL_ERROR;
    payload: string;
}


export type FiatWithdrawalActions =
    SetFiatWithdrawalAction
    | ClearFiatWithdrawalAction
    | FetchFiatWithdrawalAction
    | FetchFiatWithdrawalSuccessAction
    | FetchFiatWithdrawalErrorAction
