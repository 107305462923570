import React from 'react';
import styles from './FlexBox.module.css';

interface IFlexBox extends React.HTMLAttributes<HTMLDivElement>{
}

export function FlexBox({children}: IFlexBox) {
    return (
        <div
            className={styles.css}
        >
            {children}
        </div>
    );
}
