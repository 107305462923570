import React from 'react';
import styles from './secondaryParagraph.module.css';
interface IPrimaryParagraph extends React.HTMLAttributes<HTMLParagraphElement>{
}

export function SecondaryParagraph({children, ...props}:IPrimaryParagraph) {
    return (
        <p
            className={styles.css}
            {...props}
        >
            {children}
        </p>
    );
}