import {ExchangeActions, ExchangeActionTypes, ExchangeState} from "../../../../types/Crypto/Exchange/Exchange";

const initialState: ExchangeState = {
    responseExchange: {
        accountId: "",
        amount: 0,
        created: 0,
        id: "",
        instrumentId: "",
        maxRate: 0,
        operation: "",
    },
    dataExchange: {
        amount: "",
        currency: "EVER",
        direction: "BUY"
    },
    error: null,
    loading: false
}

export const exchangeReducer = (state = initialState, action: ExchangeActions): ExchangeState => {
    switch (action.type) {
        case ExchangeActionTypes.SET_DATA_EXCHANGE:
            return {...state, dataExchange: action.payload};
        case ExchangeActionTypes.CLEAR_STATE_EXCHANGE:
            return {
                ...state,   responseExchange: {
                    accountId: "",
                    amount: 0,
                    created: 0,
                    id: "",
                    instrumentId: "",
                    maxRate: 0,
                    operation: "",
                },
                dataExchange: {
                    amount: "",
                    currency: "EVER",
                    direction: "BUY"
                }
            };
        case ExchangeActionTypes.FETCH_EXCHANGE:
            return {...state, loading: false, error: null};
        case ExchangeActionTypes.FETCH_EXCHANGE_SUCCESS:
            return {...state, loading: false, responseExchange: action.payload};
        case ExchangeActionTypes.FETCH_EXCHANGE_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}