import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {AuthActions, AuthActionTypes} from "../../../types/User/Auth";
import {HistoryActions, HistoryActionTypes} from "../../../types/User/History";


export const ActionCreatorHistory = {
    fetchHistory: (currency?: string) => async (dispatch: Dispatch<HistoryActions | AuthActions>) => {
        try {
            dispatch({type: HistoryActionTypes.FETCH_HISTORY})
            const response = await axios.post('expi/txHistory', {}, {
                params: {
                  currency: currency
                },
                headers: {
                    "Authorization": "Bearer " +sessionStorage.getItem("token")
                },
                baseURL: config.url.API_URL,
            })
            if(response.data.result === 1) {
                dispatch({
                    type: HistoryActionTypes.FETCH_HISTORY_ERROR,
                    payload: `History ERROR: ${JSON.stringify(response.data)}`
                })
            } else {
                dispatch({type: HistoryActionTypes.FETCH_HISTORY_SUCCESS, payload: response.data})
            }
        } catch (e: any) {
            if (e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                dispatch({type: AuthActionTypes.SET_AUTH, payload: false})
            } else {
                dispatch({
                    type: HistoryActionTypes.FETCH_HISTORY_ERROR,
                    payload: `History ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR History: ", e.response)
            }
        }
    },
}

