import React from 'react';
import styles from './ButtonPrimary.module.css';

interface IButtonPrimaryProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    title?: string
}

export function ButtonPrimary({title="button", ...props}: IButtonPrimaryProps) {

    return (
        <button
            className={styles.css}
            {...props}
        >
            {title}
        </button>
    );
}
