import {IPaymentRequest} from "../../model/IPaymentRequest";

export interface PaymentRequestListState {
    responsePaymentRequestList: IPaymentRequest[],
    error: null | string,
    loading: boolean
}

export enum PaymentRequestListActionTypes {
    FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST",
    FETCH_PAYMENT_REQUEST_SUCCESS = "FETCH_PAYMENT_REQUEST_SUCCESS",
    FETCH_PAYMENT_REQUEST_ERROR = "FETCH_PAYMENT_REQUEST_ERROR",
}

interface FetchPaymentRequestListAction {
    type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST;
}

interface FetchPaymentRequestListSuccessAction {
    type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_SUCCESS;
    payload: IPaymentRequest[];
}

interface FetchPaymentRequestListErrorAction {
    type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_ERROR;
    payload: string;
}

export type PaymentRequestListActions =
    FetchPaymentRequestListAction
    | FetchPaymentRequestListSuccessAction
    | FetchPaymentRequestListErrorAction