import React, {ReactNode} from 'react';
import styles from './field.module.css';

interface IFieldProps {
    children: ReactNode,
    title: string,
    id: string,
    error?: string
}

export function Field({children, id, error, title}: IFieldProps) {
    return (
        <div
            className={styles.wrapper}
        >
            <div
                className={styles.labelBox}
            >
                <label
                    className={styles.label}
                    htmlFor={id}
                >
                    {title}
                </label>
                {
                    error && <span className={styles.error}>{error}</span>
                }
            </div>
            {children}
        </div>
    );
}
