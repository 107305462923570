import {
    WithdrawalCryptoActions,
    WithdrawalCryptoActionTypes,
    WithdrawalCryptoState
} from "../../../../types/Crypto/WithdrawalCrypto";

const initialState: WithdrawalCryptoState = {
    order: {
        result: 0,
        desc: "",
        account: "",
        amount: 0,
        currency: "",
        sentTo: ""
    },
    data: {
        to: "",
        amount: "0",
        currency: "",
        description: ""
    },
    fee: 0,
    error: null,
    loading: true,
}

export const withdrawalCryptoReducer = (state = initialState, action: WithdrawalCryptoActions): WithdrawalCryptoState => {
    switch (action.type) {
        case WithdrawalCryptoActionTypes.SET_DATA_WITHDRAWAL_CRYPTO:
            return {...state, error: null, data: action.payload};
        case WithdrawalCryptoActionTypes.CLEAR_STATE_WITHDRAWAL_CRYPTO:
            return {
                ...state, order: {
                    result: 0,
                    desc: "",
                    account: "",
                    amount: 0,
                    currency: "",
                    sentTo: ""
                },
                data: {
                    to: "",
                    amount: "",
                    currency: "",
                    description: ""
                },
                fee: 0,
                error: null,
                loading: true,
            };
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO:
            return {...state, loading: true};
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_SUCCESS:
            return {...state, loading: false, order: action.payload};
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_ERROR:
            return {...state, loading: false, error: action.payload};
        case WithdrawalCryptoActionTypes.CLEAR_WITHDRAWAL_CRYPTO_FEE:
            return {...state, fee: 0};
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE:
            return {...state, loading: true};
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_SUCCESS:
            return {...state, loading: false, fee: action.payload};
        case WithdrawalCryptoActionTypes.FETCH_WITHDRAWAL_CRYPTO_FEE_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}