import React from 'react';
import loader from "../../../image/loader/loader.png"
import "./loader.css"

type LoaderProps = {
    left?: string,
}
const Loader = ({left = "55%"}: LoaderProps) => {
    return (
        <img
            style={{left: left}}
            className="image"
            src={loader}
            alt="Loading"
            width="120"
            height="120"
        />
    );
};

export default Loader;