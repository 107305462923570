import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {
    BindPaymentRequestActions,
    BindPaymentRequestActionTypes
} from "../../../types/PaymentRequiest/BindPaymentRequest";


export const ActionCreatorBindPaymentRequest = {
    setBindPaymentRequest: (invoiceId: string) => {
        return (dispatch: Dispatch<BindPaymentRequestActions>) => {
            dispatch({type: BindPaymentRequestActionTypes.SET_PAYMENT_REQUEST_ID, payload: invoiceId})
        }
    },
    clearBindPaymentRequest: () => {
        return (dispatch: Dispatch<BindPaymentRequestActions>) => {
            localStorage.removeItem("invoiceId")
            dispatch({type: BindPaymentRequestActionTypes.CLEAR_ADD_PAYMENT_REQUEST})
        }
    },

    fetchAddPaymentRequest: (invoiceId: string) => {
        return async (dispatch: Dispatch<BindPaymentRequestActions>) => {
            try {
                dispatch({type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST})
                const response = await axios.get(`acquiring/addAccToInvoice/${invoiceId}`, {
                    baseURL: config.url.API_URL,
                    headers: {
                        "Authorization": "Bearer " +sessionStorage.getItem("token")
                    }
                })
                if(response.data.status === 0) {
                    dispatch({
                        type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_SUCCESS,
                        payload: response.data
                    })
                    dispatch({type: BindPaymentRequestActionTypes.CLEAR_ADD_PAYMENT_REQUEST})
                } else {
                    dispatch({
                        type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_ERROR,
                        payload: `AddPaymentRequest ERROR: ${JSON.stringify(response.data)}`
                    })
                    console.log("ERROR AddPaymentRequest: ", response)
                }

            } catch (e: any) {
                dispatch({
                    type: BindPaymentRequestActionTypes.FETCH_ADD_PAYMENT_REQUEST_ERROR,
                    payload: `AddPaymentRequest ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR AddPaymentRequest: ", e.response)
            }
        };
    },
}

