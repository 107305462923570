/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';

import {useHistory} from "react-router-dom";

import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import {RouteNames} from "../types/Route";
import {positiveNumberValid} from "../utils/validation";
import {
    Typography,
    Box,
    Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, Fade, InputAdornment, Modal, Snackbar, TextField,
} from '@mui/material';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import TitleMain from "../components/UI/TitleMain";

import {
    Colors,
    Fonts,
    ModalStyle,
    TextFieldStyle,
} from "../utils/constants";


import BTC from "../image/currency/BTC.png";
import EPI from "../image/currency/EPI.png";
import ETH from "../image/currency/ETH.png";
import EVER from "../image/currency/EVER.png";
import AddWallet from "../components/UI/AddWalletCard";
import {getNoOpenWalletsList} from "../utils/getNoWalletsList";


const Balance: FC = () => {
    const {user, error} = useTypeSelector(state => state.user);
    const {walletAddress} = useTypeSelector(state => state.addressWallet);
    const {data, order, fee} = useTypeSelector(state => state.withdrawalCrypto);
    const {responseCurrencyList} = useTypeSelector(state => state.currencyList);
    const errorWithdrawalCrypto = useTypeSelector(state => state.withdrawalCrypto.error);
    const {invoiceId} = useTypeSelector(state => state.bindPaymentRequest);
    const noOpenWalletsList = getNoOpenWalletsList(user.balanceList, responseCurrencyList);
    const history = useHistory();
    const {
        fetchAddress,
        fetchBalance,
        setWithdrawalCryptoData,
        fetchWithdrawalCrypto,
        fetchWithdrawalCryptoFee,
        clearWithdrawalCryptoFee,
        clearWithdrawalCryptoData,
    } = useActions();

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [qr, setQr] = useState("");
    const [address, setAddress] = useState("");
    const [openModal, setOpenModal] = useState({
        openWithdrawal: false,
        openDeposit: false,
        openOrder: false,
        action: "",
        currency: "",
        walletName: "",
    });

    const handleChangeWithdrawal = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "amount") {
            if (positiveNumberValid(event.target.value)) {
                setWithdrawalCryptoData({
                    ...data,
                    currency: openModal.currency,
                    amount: event.target.value
                })
            }
        } else {
            setWithdrawalCryptoData({
                ...data,
                currency: openModal.currency,
                [event.target.name]: event.target.value
            })
        }
    };

    const sentWithdrawal = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        fetchWithdrawalCrypto(data);
    };

    const handleOpenModal = (action: string, currency: string, walletName: string) => {
        if (action === "withdrawal") {
            setOpenModal({
                ...openModal,
                openWithdrawal: true,
                action: action,
                currency: currency,
                walletName: walletName
            })
        }
        if (action === "deposit") {
            let address = walletAddress.find(address => address.currency === currency)
            if (address) {
                setQr(address.qr)
                setAddress(address.address)
            }
            setOpenModal({...openModal, openDeposit: true, action: action, currency: currency, walletName: walletName})
        }
    };

    const handleCloseModal = (action: string) => {
        if (action === "withdrawal") {
            setOpenModal({...openModal, openWithdrawal: false, action: "", currency: "", walletName: ""})
        }
        if (action === "deposit") {
            setOpenModal({...openModal, openDeposit: false, action: "", currency: "", walletName: ""})
        }
        if (action === "order") {
            fetchBalance();
            setOpenModal({...openModal, openOrder: false, action: "", currency: "", walletName: ""})
        }
        clearWithdrawalCryptoData();
    };

    const handleExchangeAction = (currency: string) => {
        if (currency === "EVER" || currency === "EPI") {
            history.push(RouteNames.EXCHANGE_PARAMS + "EVER")
        } else {
            history.push(RouteNames.EXCHANGE_PARAMS + currency)
        }
    };

    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    useEffect(() => {
        if (invoiceId) {
            history.push(RouteNames.PAYMENT_REQUEST)
        }
        fetchAddress();
        clearWithdrawalCryptoData();

    }, []);

    useEffect(() => {
        if (Number(data.amount) > 0) {
            fetchWithdrawalCryptoFee(Number(data.amount), data.currency)
        } else {
            clearWithdrawalCryptoFee();
        }
    }, [data.amount]);

    useEffect(() => {
        if (order.account) {
            setOpenModal({...openModal, openOrder: true, openWithdrawal: false})
        }
    }, [order.account]);

    if (error) {
        return <Typography>{error}</Typography>
    }
    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
        >
            <Snackbar
                autoHideDuration={1000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                message="Address copied to clipboard"
            />
            <Box>
                <TitleMain>
                    CRYPTO WALLETS
                </TitleMain>
            </Box>
            {/*WALLETS===============================================================================================*/}
            <Box
                display="flex"
                flexWrap="wrap"
            >
                {user.balanceList.map((wallet, index) =>
                    <Card
                        key={index}
                        sx={{minWidth: 260, marginRight: 3, marginBottom: 3, paddingY: 2, paddingX: 2}}
                    >
                        <CardContent>
                            <Box
                                display="flex"
                                alignItems="center"
                                marginBottom={3}
                            >
                                <img
                                    style={{maxWidth: 50, height: 50}}
                                    src={wallet.currency === "BTC"
                                        ? BTC

                                        : wallet.currency === "ETH"
                                            ? ETH
                                            : wallet.currency === "EPI"
                                                ? EPI
                                                : wallet.currency === "EVER"
                                                    ? EVER
                                                    : ""
                                    }
                                    alt={wallet.name}
                                />
                                <Box
                                    marginLeft={2}
                                >
                                    <Typography
                                        variant="h4"
                                    >
                                        {wallet.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection={"column"}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                >
                                    {wallet.balance}
                                </Typography>
                                <Typography
                                    variant={"body1"}
                                    color={Colors.textSecondary}
                                    gutterBottom
                                >
                                    € {wallet.balanceInEur}
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <ButtonGroup
                                variant="text"
                                aria-label="outlined primary button group"
                            >
                                <Button
                                    onClick={handleOpenModal.bind(null, "withdrawal", wallet.currency, wallet.name)}
                                    sx={{color: Colors.primary}}
                                >
                                    Withdrawal
                                </Button>
                                <Button
                                    variant={"contained"}
                                    onClick={handleOpenModal.bind(null, "deposit", wallet.currency, wallet.name)}
                                >
                                    Deposit
                                </Button>
                                <Button
                                    onClick={handleExchangeAction.bind(null, wallet.currency)}
                                    sx={{color: Colors.primary}}
                                >
                                    Exchange
                                </Button>
                            </ButtonGroup>
                        </CardActions>
                    </Card>
                )}

                {
                    noOpenWalletsList.length > 0 &&
                    noOpenWalletsList.map((wallet, index) =>
                        <AddWallet
                            key={index}
                            currency={wallet.id}
                        />
                    )
                }
            </Box>
            {/*WITHDRAWAL MODAL======================================================================================*/}
            <Modal
                aria-labelledby="withdrawal-crypto-modal-title"
                aria-describedby="withdrawal-crypto-modal-description"
                open={openModal.openWithdrawal}
                onClose={handleCloseModal.bind(null, "withdrawal")}
                closeAfterTransition
                sx={{}}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal.openWithdrawal}>
                    <Box sx={ModalStyle}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={3}
                        >
                            <Typography
                                id="withdrawal-crypto-modal-title"
                                variant="h5"
                                color={Colors.textPrimary}
                                fontFamily={Fonts.intro}
                                // marginBottom={3}
                            >
                                {`Send  ${openModal.walletName}`}
                            </Typography>
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            component="form"
                            onSubmit={sentWithdrawal}
                        >
                            <TextField
                                fullWidth
                                autoFocus
                                sx={TextFieldStyle}
                                error={!!errorWithdrawalCrypto}
                                label="To"
                                type="text"
                                id={`to`}
                                name={`to`}
                                value={data.to}
                                onChange={handleChangeWithdrawal}
                                helperText={errorWithdrawalCrypto}
                                placeholder={`Paste ${openModal.walletName.toLocaleLowerCase()} address`}
                            />
                            <TextField
                                fullWidth
                                sx={TextFieldStyle}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <span className={
                                            openModal.currency === "EPI"
                                                ? "icon-eupi_symbol"
                                                : openModal.currency === "BTC"
                                                    ? "icon-bitcoin_symbol"
                                                    : openModal.currency === "ETH"
                                                        ? "icon-icon-eth_symbol"
                                                        : openModal.currency === "EVER" ? "icon-icon-ever_symbol"
                                                            : ""
                                        } style={{fontSize: 15, fontFamily: "Icon"}}/>
                                    </InputAdornment>,
                                }}
                                placeholder={"0"}
                                label="Amount"
                                type="number"
                                id={`amount`}
                                name={`amount`}
                                value={data.amount}
                                onChange={handleChangeWithdrawal}
                            />
                            <Typography
                                color={Colors.textPrimary}
                                mb={3}
                            >
                                Fee: <span className={
                                openModal.currency === "EPI"
                                    ? "icon-eupi_symbol"
                                    : openModal.currency === "BTC"
                                        ? "icon-bitcoin_symbol"
                                        : openModal.currency === "ETH"
                                            ? "icon-icon-eth_symbol"
                                            : openModal.currency === "EVER" ? "icon-icon-ever_symbol"
                                                : ""
                            } style={{fontSize: 14, fontFamily: "Icon"}}/> {fee}
                            </Typography>
                            <TextField
                                fullWidth
                                sx={TextFieldStyle}
                                label="Description"
                                type="text"
                                id={`description`}
                                name={`description`}
                                value={data.description}
                                onChange={handleChangeWithdrawal}
                            />
                            <Typography
                                color={Colors.textPrimary}
                                mb={3}
                                textAlign="right"
                            >
                                Total: <span className={
                                openModal.currency === "EPI"
                                    ? "icon-eupi_symbol"
                                    : openModal.currency === "BTC"
                                        ? "icon-bitcoin_symbol"
                                        : openModal.currency === "ETH"
                                            ? "icon-icon-eth_symbol"
                                            : openModal.currency === "EVER" ? "icon-icon-ever_symbol"
                                                : ""
                            } style={{fontSize: 14, fontFamily: "Icon"}}/> {fee + Number(data.amount)}
                            </Typography>
                            <Box>
                                <Button
                                    disabled={Number(data.amount) === 0}
                                    fullWidth
                                    variant={"contained"}
                                    type="submit"
                                >
                                    Sent
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            {/*WITHDRAWAL ORDER MODAL================================================================================*/}
            <Modal
                aria-labelledby="withdrawal-crypto-order-modal-title"
                aria-describedby="withdrawal-crypto-order-modal-description"
                open={openModal.openOrder}
                onClose={handleCloseModal.bind(null, "order")}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal.openOrder}>
                    <Box sx={ModalStyle}>
                        <Typography
                            id="withdrawal-crypto-order-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                            marginBottom={3}
                        >
                            {`Withdrawal ${order.currency} detail:`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-account"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Account: ${order.account}`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-sent"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Sent to account: ${order.sentTo}`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order--modal-title"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Description: ${data.description}`}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-amount"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Amount: `}
                            <span className={
                                order.currency === "EPI"
                                    ? "icon-eupi_symbol"
                                    : order.currency === "BTC"
                                        ? "icon-bitcoin_symbol"
                                        : order.currency === "ETH"
                                            ? "icon-icon-eth_symbol"
                                            : order.currency === "EVER" ? "icon-icon-ever_symbol"
                                                : ""
                            } style={{fontSize: 12, fontFamily: "Icon"}}/> {order.amount}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-fee"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={1}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Fee: `}
                            <span className={
                                order.currency === "EPI"
                                    ? "icon-eupi_symbol"
                                    : order.currency === "BTC"
                                        ? "icon-bitcoin_symbol"
                                        : order.currency === "ETH"
                                            ? "icon-icon-eth_symbol"
                                            : order.currency === "EVER" ? "icon-icon-ever_symbol"
                                                : ""
                            } style={{fontSize: 12, fontFamily: "Icon"}}/> {fee}
                        </Typography>
                        <Typography
                            id="withdrawal-crypto-order-modal-fee"
                            variant="body2"
                            textAlign="right"
                            color={Colors.textPrimary}
                            marginBottom={3}
                            sx={{wordBreak: "break-all"}}
                        >
                            {`Total: `}
                            <span className={
                                order.currency === "EPI"
                                    ? "icon-eupi_symbol"
                                    : order.currency === "BTC"
                                        ? "icon-bitcoin_symbol"
                                        : order.currency === "ETH"
                                            ? "icon-icon-eth_symbol"
                                            : order.currency === "EVER" ? "icon-icon-ever_symbol"
                                                : ""
                            } style={{fontSize: 12, fontFamily: "Icon"}}/> {fee + order.amount}
                        </Typography>
                        <Button
                            variant={"contained"}
                            fullWidth
                            onClick={handleCloseModal.bind(null, "order")}
                        >
                            Close
                        </Button>
                    </Box>
                </Fade>
            </Modal>
            {/*DEPOSIT MODAL=========================================================================================*/}
            <Modal
                aria-labelledby="deposit-modal-title"
                aria-describedby="deposit-modal-description"
                open={openModal.openDeposit}
                onClose={handleCloseModal.bind(null, "deposit")}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal.openDeposit}>
                    <Box sx={ModalStyle}>
                        <Typography
                            id="deposit-modal-title"
                            variant="h5"
                            color={Colors.textPrimary}
                            fontFamily={Fonts.intro}
                        >
                            {`Request ${openModal.walletName}`}
                        </Typography>
                        <Box
                            textAlign="center"
                            marginBottom={2}
                        >
                            <img src={qr}
                                 alt="QRCode"/>
                        </Box>
                        <Typography
                            id="confirm-modal-title"
                            variant="caption"
                            color={Colors.textSecondary}
                            sx={{wordBreak: "break-all"}}
                        >
                            Address:
                        </Typography>
                        <Typography
                            id="confirm-modal-title"
                            variant="body2"
                            color={Colors.textPrimary}
                            marginBottom={3}
                            sx={{wordBreak: "break-all", cursor: "pointer"}}
                            onClick={() => {
                                navigator.clipboard.writeText(address)
                                setOpenSnackBar(true)
                            }}
                        >
                            {address} <ContentCopyOutlinedIcon sx={{fontSize: 12}}/>
                        </Typography>
                        <Button
                            variant={"contained"}
                            fullWidth
                            onClick={handleCloseModal.bind(null, "deposit")}
                        >
                            Close
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default Balance;