import {AddressActions, AddressActionTypes, AddressWalletState} from "../../../types/Crypto/AddressWallet";

const initialState: AddressWalletState = {
    walletAddress: [{
        currency: "",
        account: "",
        address: "",
        qr: "",
    }],
    error: null,
    loading: true,
}

export const addressReducer = (state = initialState, action: AddressActions): AddressWalletState => {
    switch (action.type) {
        case AddressActionTypes.FETCH_ADDRESS:
            return {...state, loading: true};
        case AddressActionTypes.FETCH_ADDRESS_SUCCESS:
            return {...state, loading: false, walletAddress: action.payload};
        case AddressActionTypes.FETCH_ADDRESS_ERROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}