export interface AddWalletState {
    responseAddWallet: string,
    error: null | string,
    loading: boolean
}

export enum AddWalletActionTypes {
    FETCH_ADD_WALLET = "FETCH_ADD_WALLET",
    FETCH_ADD_WALLET_SUCCESS = "FETCH_ADD_WALLET_SUCCESS",
    FETCH_ADD_WALLET_ERROR = "FETCH_ADD_WALLET_ERROR",
}

interface FetchAddWalletAction {
    type: AddWalletActionTypes.FETCH_ADD_WALLET;
}

interface FetchAddWalletSuccessAction {
    type: AddWalletActionTypes.FETCH_ADD_WALLET_SUCCESS;
    payload: string;
}

interface FetchAddWalletErrorAction {
    type: AddWalletActionTypes.FETCH_ADD_WALLET_ERROR;
    payload: string;
}

export type AddWalletActions =
    FetchAddWalletAction
    | FetchAddWalletSuccessAction
    | FetchAddWalletErrorAction