/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {useActions} from "../../hooks/useActions";
import BTC from "../../image/currency/BTC.png";
import ETH from "../../image/currency/ETH.png";
import EPI from "../../image/currency/EPI.png";
import EVER from "../../image/currency/EVER.png";
import {Box, Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";

interface IAddWalletCard {
    currency: string,
}

export default function AddWalletCard(props: IAddWalletCard) {
    const {loading} = useTypeSelector(state => state.addWallet);
    const {fetchAddWallet, fetchBalance} = useActions();

    const handleClick = () => {
        fetchAddWallet(props.currency);
        setTimeout(()=>{
            fetchBalance();
        },500);
    };

    return (
        <Card
            sx={{
                minWidth: 420,
                marginRight: 3,
                marginBottom: 3,
                paddingY: 2,
                paddingX: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
        }}
        >
            <CardContent>
                <Box
                    display="flex"
                    marginBottom={3}
                >
                    <img
                        style={{maxWidth: 50, height: 50}}
                        src={
                            props.currency === "BTC"
                                ? BTC
                                : props.currency === "ETH"
                                    ? ETH
                                    : props.currency === "EPI"
                                        ? EPI
                                        : props.currency === "EVER"
                                            ? EVER
                                            : ""
                        }
                        alt={props.currency}
                    />
                    <Box
                        marginLeft={2}
                    >
                        <Typography
                            variant="h4"
                        >
                            {props.currency}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardActions>
                <ButtonGroup
                    variant="text"
                    aria-label="outlined primary button group"
                >
                    <LoadingButton
                        loading={loading}
                        variant={"contained"}
                        onClick={handleClick}
                    >
                        {`Open ${props.currency}`}
                    </LoadingButton>

                </ButtonGroup>
            </CardActions>
        </Card>
    );
}
