import {useEffect, useState} from 'react';

const useHeightScreen = () => {

    const [height, setHeight] = useState(window.screen.availHeight);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newHeight = window.screen.availHeight;
            setHeight(newHeight);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);
    return height
};

export default useHeightScreen;