import React from 'react';
import {H3} from "../../components/UI/Typography/H3";
import {COLORS} from "../../utils/styles";
import {H2} from "../../components/UI/Typography/H2";
import {Form} from "../../components/UI/Form";
import {Field} from "../../components/UI/Form/Field";
import {Input} from "../../components/UI/Input";
import {ButtonPrimary} from "../../components/UI/Buttons/ButtonPrimary";
import {PrimaryParagraph} from "../../components/UI/Typography/Paragraph/PimaryParagraph";
import {SecondaryParagraph} from "../../components/UI/Typography/Paragraph/SecondaryParagraph";

const Confirm = () => {

    const submit = (e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("confirm")
    }

    return (
        <React.Fragment>
            <H3
                style={{color: COLORS.textSecondary}}
            >
                START FOR FREE
            </H3>
            <H2>
                Email confirmation
            </H2>
            <PrimaryParagraph>
                We&nbsp;have sent you an&nbsp;email with a&nbsp;4-digit code. Enter it&nbsp;in&nbsp;the field below.
            </PrimaryParagraph>

            <Form
                style={{marginBottom: "40px"}}
                onSubmit={submit}
            >
                <Field
                    title={"Verification email code"}
                    id={"confirmPhone"}
                >
                    <Input
                        placeholder={"#######"}
                        id={"confirmEmail"}
                        type={"text"}
                    />
                </Field>
                <Field
                    title={"Verification phone code"}
                    id={"confirmPhone"}
                >
                    <Input
                        placeholder={"#######"}
                        id={"confirmPhone"}
                        type={"text"}
                    />
                </Field>
                <ButtonPrimary
                    title={"Join"}
                    type={"submit"}
                />
            </Form>
            <SecondaryParagraph>
                Resend email with code in 89 seconds
            </SecondaryParagraph>
        </React.Fragment>
    );
};

export default Confirm;
