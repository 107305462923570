import React from 'react';

export function EyeSlash() {
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8362 15.754L12.617 13.5334C11.7588 13.8402 10.8311 13.897 9.94183 13.6972C9.05258 13.4974 8.23835 13.0492 7.59388 12.4047C6.94941 11.7603 6.50119 10.946 6.30139 10.0568C6.10159 9.16755 6.15841 8.23984 6.46525 7.38162L3.63275 4.54913C1.28975 6.63225 0 9 0 9C0 9 4.125 16.5625 11 16.5625C12.3206 16.5579 13.6262 16.2828 14.8362 15.754V15.754ZM7.16375 2.246C8.37382 1.71721 9.67944 1.44205 11 1.4375C17.875 1.4375 22 9 22 9C22 9 20.7089 11.3664 18.3686 13.4522L15.5334 10.617C15.8402 9.75878 15.897 8.83108 15.6972 7.94183C15.4974 7.05258 15.0492 6.23835 14.4047 5.59388C13.7603 4.94941 12.946 4.50119 12.0568 4.30139C11.1675 4.10159 10.2398 4.15841 9.38162 4.46525L7.16375 2.24737V2.246Z"
                fill="black"/>
            <path
                d="M7.59681 8.51324C7.52117 9.04167 7.56964 9.58045 7.73838 10.0869C7.90712 10.5933 8.19149 11.0535 8.56895 11.431C8.94641 11.8084 9.40659 12.0928 9.91304 12.2615C10.4195 12.4303 10.9583 12.4788 11.4867 12.4031L7.59543 8.51324H7.59681ZM14.4031 9.48674L10.5132 5.59549C11.0416 5.51986 11.5804 5.56833 12.0868 5.73707C12.5933 5.90581 13.0535 6.19017 13.4309 6.56763C13.8084 6.9451 14.0927 7.40528 14.2615 7.91172C14.4302 8.41816 14.4787 8.95694 14.4031 9.48537V9.48674ZM18.7632 17.7367L2.26318 1.23674L3.23668 0.263245L19.7367 16.7632L18.7632 17.7367Z"
                fill="black"/>
        </svg>
    );
}