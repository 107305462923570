import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../../utils/config";
import {PaymentRequestListActions, PaymentRequestListActionTypes} from "../../../types/PaymentRequiest/PaymentRequest";


export const ActionCreatorPaymentRequestList = {
    fetchPaymentRequestList: () => {
        return async (dispatch: Dispatch<PaymentRequestListActions>) => {
            try {
                dispatch({type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST})
                const response = await axios.get('acquiring/getInvoiceList', {
                    baseURL: config.url.API_URL,
                    headers: {
                        "Authorization": "Bearer " +sessionStorage.getItem("token")
                    }
                })
                dispatch({type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_SUCCESS, payload: response.data})
            } catch (e: any) {
                dispatch({
                    type: PaymentRequestListActionTypes.FETCH_PAYMENT_REQUEST_ERROR,
                    payload: `PaymentRequestList ERROR: ${JSON.stringify(e.response.data)}`
                })
                console.log("ERROR PaymentRequestList: ", e.response)
            }
        };
    },
}

